import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';
import '../../styles/styleNew.css';
import '../../styles/style.css';
import { Grid, TextField, Button, Radio, RadioGroup, FormControlLabel, FormGroup, Checkbox, Typography } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { NavLink } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import { useFormik } from 'formik';
import baseUrl from '../../config/config';
import { FormContext, LoanOfficerContext, ApplicationStatusContext, TestApplicationContext } from '../../../context/FormContext';
import { KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles } from '@material-ui/core/styles';
import Reviewmodal from './Reviewmodal';
import PageLoader from '../../views/commonViews/PageLoader';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import CircularProgress from '@material-ui/core/CircularProgress';
import CommonHeader from './CommonHeader';
import Autocomplete from '@material-ui/lab/Autocomplete';

const show = {
    display: 'flex',
}
const hide = {
    display: 'none'
}

function Mgptab(props) {
    const ref = useRef()
    let valuationProviderData = {};
    var currentTime = new Date();
    let isAvmUpdatedValue = false;
    const [forms, setForms] = useContext(FormContext);
    const [newEntries,setNewEntries] = useContext(LoanOfficerContext);
    const [newStatus,setNewStatus] = useContext(ApplicationStatusContext);
    const [isTest,setIsTest] = useContext(TestApplicationContext);
    const [mortgageMoreInfo, setMortgageMoreInfo] = useState(false);
    const [coBorrower, setCoBorrower] = useState('no');
    const [realEstateAgent, setRealEstateAgent] = useState('no');
    const [loanOfficer, setLoanOfficer] = useState('no');
    const [testApp, setTestApp] = useState(false);
    const [submittedCRM, setSubmittedCRM] = useState(false);
    const [countyList, setCountyList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [propertyMoreInfo, setPropertyMoreInfo] = useState(false);
    const [propertyTypeList, setPropertyTypeList] = useState([]);
    const [validationSource, setValidationSource] = useState([]);
    const [propertyHome, setPropertyHome] = useState([]);
    const [hearAboutUs, setHearAboutUs] = useState([]);
    const [concern, setConcern] = useState([]);
    const [sellingTimeFrame, setSellingTimeFrame] = useState([]);
    const [loanProgram, setLoanProgram] = useState([]);
    const [loanType, setLoanType] = useState([]);
    const [loanPurpose, setLoanPurpose] = useState([]);
    const [mortgageSecond, setMortgageSecond] = useState('false');
    const [mortgageFirst, setMortgageFirst] = useState('false');
    const [loansInfo, setLoansInfo] = useState('false');
    const [otherFeeInfo, setOtherFeeInfo] = useState('false');
    const [isDefects, setIsDefects] = useState();
    const [propertySecond, setPropertySecond] = useState('false');
    const [propertyFirst, setPropertyFirst] = useState('false');
    const [propertyLoansInfo, setPropertyLoansInfo] = useState('false');
    const [propertyOtherFeeInfo, setPropertyOtherFeeInfo] = useState('false');
    const [takeOutOffer, setTakeOutOffer] = useState('no');
    const [zipcodeId, setZipcodeId] = useState();
    const [zipCodeValue, setZipCodeValue] = useState();
    const [loaderStatus, setLoaderStatus] = useState(false);
    const [radioErr, setRadioError] = useState()
    const [originatorNames, setOriginatorNames] = useState([])
    const [originatorNameDefaultValue, setOriginatorNameDefaultValue] = useState(null);
    const [partnerContactRoleLoan, setPartnerContactRoleLoan] = useState(null)
    const [partnerContactRoleRealEstate, setPartnerContactRoleRealEstate] = useState(null)
    const [loanOfficerList, setLoanOfficerList] = useState([])
    const [realEstateAgentList, setRealEstateAgentList] = useState([])
    const [loanOfficerName, setLoanOfficerName] = useState(false);
    const [realEstateAgentName, setRealEstateAgentName] = useState(false);
    const [addPartnerLoanOfficer, setAddPartnerLoanOfficer] = useState([]);
    const [addPartnerRealEstate, setAddPartnerRealestate] = useState([]);
    const [mgpLoader, setMgpLoader] = useState(false);
    const [valuationProvider, setValuationProvider] = useState([])
    const [displayProvider, setDisplayProvider] = useState([])
    const [addressAVMValidate, setAddressAVMValidate] = useState([])
    const [displayPropertyError, setDisplayPropertyError] = useState(true)
    const [avmValuationManual, setAvmValuationManual] = useState(false)
    const [loanofficerErrorMsg, setLoanOfficerErrorMsg] = useState('')
    const [realEstateAgentErrorMsg, setRealEstateAgentErrorMsg] = useState('')
    const [confirmLOButtonDisplay, setConfirmLOButtonDisplay] = useState(false)
    const [confirmRSAButtonDisplay, setConfirmRSAButtonDisplay] = useState(false)
    const [addLOButtonDisplay, setAddLOButtonDisplay] = useState(false)
    const [addRSAButtonDisplay, setAddRSAButtonDisplay] = useState(false)
    const [nuthshellAPILoader, setNutshellAPILoader] = useState(false)
    const [isLoanOfficerActive, setIsLoanOfficerActive] = useState('')
    const [isRealEstateAgentActive, setIsRealEstateAgentActive] = useState('')
    const [checkLoResEdit, setCheckLoResEdit] = useState(null)
    const [checkRsaResEdit, setCheckRsaResEdit] = useState(null)
    const [displayAddLo, setDisplayAddLo] = useState(false)
    const [displayAddLoCheck, setDisplayAddLoCheck] = useState(false)
    const [displayAddRSA, setDisplayAddRSA] = useState(false)
    const [displayAddRSACheck, setDisplayAddRSACheck] = useState(false)
    const [responseLOEditAdd, setResponseLOEditAdd] = useState(false)
    const [responseRSAEditAdd, setResponseRSAEditAdd] = useState(false)
    const [loAddDisable,setLoAddDisable] = useState(false);
    const [agentAddDisable,setAgentAddDisable] = useState(false);
    const [defectsList,setDefectsList] = useState();
    const [conditionList,setConditionList] = useState();
    const [checkedBoxes, setCheckedBoxes] = useState([]);
    const [alterationsAndProblems, setAlterationsAndProblems] = useState();
    const [isDefectsChecked, setIsDefectsChecked] = useState(false);
    const [isClickedLoNo, setIsClickedLoNo] = useState(false);
    const [isClickedAgentNo, setIsClickedAgentNo] = useState(false);
    const [cxSpecialist,setCxSpecialist] = useState();
    const [statusOffer,setStatusOffer] = useState();
    const [primaryResidence,setPrimaryResidence] = useState('');
    const [isOwner,setIsOwner] = useState("");
    const [loanOfficerItems, setLoanOfficerItems] = useState([]);
    const [agentItems, setAgentItems] = useState([]);
    const [isOpen,setIsOpen] = useState(false);
    const [isAgentOpen,setIsAgentOpen] = useState(false);
    const [showAddButton,setShowAddButton] = useState(true);
    const [showAddAgentButton,setShowAddAgentButton] = useState(true);
    const [selectedLo,setSelectedLo] = useState(null);
    const [selectedAgent,setSelectedAgent] = useState(null);
    const [editedLo,setEditedLo] = useState(null);
    const [editedAgent,setEditedAgent] = useState(null);
    const [isLoAdded,setIsLoAdded] = useState(false);
    const [isAgentAdded,setIsAgentAdded] = useState(false);
    const [originatorChangeLoConfirm,setOriginatorChangeLoConfirm] = useState(false);
    const [originatorChangeAgentConfirm,setOriginatorChangeAgentConfirm] = useState(false);
    const [isComponentMounted, setIsComponentMounted] = useState(false);
    const [loConfirmResults,setLoConfirmResults] = useState(null);
    const [agentConfirmResults,setAgentConfirmResults] = useState(null);
    const [loanOfficerState, setLoanOfficerState] = useState(false)
    const [realEstateAgentState, setRealEstateAgentState] = useState(false);
    const [loErrDisplay, setLoErrDisplay] = useState(false);
    const [rsaErrDisplay, setRsaErrDisplay] = useState(false);
    const [loStateChange, setLoStateChange] = useState(false);
    const [rsaStateChange, setRsaStateChange] = useState(false);
    const [isSaved,setIsSaved] = useState(false);
    const [isOriginatorChanged,setIsOriginatorChanged] = useState(false);

    const useStyles = makeStyles((theme) => ({
        table: {
            size: 'large',
            height: '100%',
            minWidth: '500px !important',
            borderCollapse: 'collapse'
        },
    }));
    const handleClose1 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };
    const vertical = 'top';
    const horizontal = 'center';
    const [detail, setDetail] = React.useState('');
    const [severity_type, setSeverityType] = React.useState('');
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [openSubmitLoanOfficerDetails, setOpenSubmitLoanOfficerDetails] = React.useState(false);
    const [openSubmitRealEstateAgentDetails, setOpenSubmitRealEstateAgentDetails] = React.useState(false);
    const [openSubmitLoanOfficerEditDetails, setOpenSubmitLoanOfficerEditDetails] = useState(false);
    const [openSubmitRealEstateAgentEditDetails, setOpenSubmitRealEstateAgentEditDetails] = useState(false);

    const classes = useStyles();
    const handleLoanOfficerDetails = () => {
        setLoanOfficerErrorMsg('')
        setOpenSubmitLoanOfficerDetails(!openSubmitLoanOfficerDetails);
    };
    const handleLoanOfficerEditDetails = () => {
        setLoErrDisplay(false)
        setOpenSubmitLoanOfficerEditDetails(!openSubmitLoanOfficerEditDetails)
    }
    const handleRealEstateAgentEditDetails = () => {
        setRsaErrDisplay(false)
        setOpenSubmitRealEstateAgentEditDetails(!openSubmitRealEstateAgentEditDetails)
    }
    const handleRealEstateAgentDetails = () => {
        setRealEstateAgentErrorMsg('')
        setOpenSubmitRealEstateAgentDetails(!openSubmitRealEstateAgentDetails);
    };
    const initialValues = {
        applicationID: null,
        createDate: null,
        externalReference: null,
        updatedBy: null,
        createdBy: null,
        updatedDate: null,
        originatorName: null,
        is_loan_officer: false,
        loanOfficerFullName: null,
        loanOfficerFirstName: '',
        loanOfficerLastName: '',
        loanOfficerEmail: '',
        loanOfficerExtension: '',
        loanOfficerPhoneNumber: '',
        realEstateAgentFullName: null,
        realEstateAgentFirstName: '',
        realEstateAgentLastName: '',
        realEstateAgentEmail: '',
        realEstateAgentPhoneNumber: '',
        realEstateAgentExtension: '',
        borrowerFirstName: '',
        borrowerEmail: '',
        borrowerLastName: '',
        borrowerPhoneNumber: '',
        homeOwnerExtension:null,
        coBorrowerFirstName: '',
        coBorrowerEmail: '',
        coBorrowerLastName: '',
        coBorrowerPhoneNumber: '',
        additionalHomeOwnerExtension: null,
        propertyInfoStreet: '',
        propertyInfoZipcode: null,
        propertyInfoCity: '',
        propertyInfoCounty: null,
        propertyInfoState: null,
        propertyInfoProType: null,
        // propertyInfoOccupied: 'true',
        // primaryResidence: '',
        primaryResidenceComments:'',
        nonOwnerComments:'',
        propertyInfoPurchase: null,
        propertyInfoPrice: null,
        ownerEstValue: null,
        equity_unlock_requested: null,
        estMortgage1Balance:null,
        mortgageInfoTax:'true',
        mort1MonthlyPayment:null,
        mortgageInfoInsurance:'true',
        mortgageInfoSecondLoanType: null,
        estMortgage2Balance:null,
        mort2MonthlyPayment:null,
        mort_other_loans:'',
        mortDescribeFees:null,
        mortOtherFeesMonthlyAmount:null,
        homeCondition:null,
        yearBuilt:null,
        aboveGradeSqft:null,
        biggestConcern:null,
        readyToSell:null,
        maxHomePrice:null,
        condoUnitNum:null,
        condoAssocName:null,
        hearAboutUs:null,
        takeOutBuyer: null,
        septicSystem:null,
        takeOutPercentage: 70,
        calqueFee: null,
        administrative_fee: null,
        // veros_avm_confidence_score: null,
        // veros_avm_fsd_value: null,
        // title_cleared: false,
        // title_comments: '',
        is_test_app: false,
        submitted_to_crm: false,
        // is_avm_updated: null,
        // is_entry_type_manual: avmValuationManual,
    }
    const appendMonth = (n) => {
        let num = n + 1;
        if (num <= 9) {
            return "0" + num;
        }
        return num
    }
    const appendDate = (n) => {
        let num = n;
        if (num <= 9) {
            return "0" + num;
        }
        return num
    }
    const dateFormat = (data) => {
        let date = new Date(data)
        let formatted_date = (appendMonth(date.getMonth())) + "-" + appendDate(date.getDate()) + "-" + date.getFullYear();
        return formatted_date.toString();
    }
    const phoneNumberFormatBorrower = (event) => {
        let brackets = event.target.value.replace(/[-()+]/g, '');
        let spaces = brackets.split(" ").join("");
        formik.values.borrowerPhoneNumber = spaces
    }
    const phoneNumberCoBorrower = (event) => {
        let brackets = event.target.value.replace(/[-()+]/g, '');
        let spaces = brackets.split(" ").join("");
        formik.values.coBorrowerPhoneNumber = spaces
    }
    const handleEmail = (e) => {
        if (e.target.value) {
            formik.setFieldValue('loanOfficerEmail', e.target.value)
        }
    }
    const handleFirstName = (e) => {
        if (e.target.value) {
            formik.setFieldValue('loanOfficerFirstName', e.target.value)
        }
    }
    const handleLastName = (e) => {
        if (e.target.value) {
            formik.setFieldValue('loanOfficerLastName', e.target.value)
        }
    }
    const handleRealEstateAgentEmail = (e) => {
        if (e.target.value) {
            formik.setFieldValue('realEstateAgentEmail', e.target.value)
        }
    }
    const handleRealEstateAgentFirstName = (e) => {
        if (e.target.value) {
            formik.setFieldValue('realEstateAgentFirstName', e.target.value)
        }
    }
    const handleRealEstateAgentLastName = (e) => {
        if (e.target.value) {
            formik.setFieldValue('realEstateAgentLastName', e.target.value)
        }
    }
    const handlePurchaseDateChange = (date) => {
        if (date != null) {
            formik.setFieldValue('propertyInfoPurchase', moment(date).format('MM/DD/YYYY'))
        } else {
            formik.setFieldValue('propertyInfoPurchase', '')
        }
    };

    const handlePrimaryResidence = (e) => {
        setPrimaryResidence(e.target.value);
        formik.setFieldValue('primaryResidenceComments','');
    }

    const handleOwnerOrNot = (e)=>{
        setIsOwner(e.target.value);
        formik.setFieldValue('nonOwnerComments','');
        formik.setFieldValue('primaryResidenceComments','');
    }

    const handleCxSpecialist = (e) =>{
        setCxSpecialist(e.target.value);
        let method = 'PATCH';
        let tokenStr = localStorage.getItem('access_token')
        let body={
            "cx_specialist":e.target.value
        }
        fetch(`${baseUrl.baseUrl}trade-in-application/${forms.trade_in_application_id}/update-cx-specialist/`, {
            method,
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${tokenStr}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
        })
            .then(async response => {
                const results = await response.json()
            })
            .catch((error) => {
                console.log('error', error)
            })
    }
    const handleApplicationStatus = (e) =>{
        setStatusOffer(e.target.value);
        let method = 'PATCH';
        let tokenStr = localStorage.getItem('access_token')
        let body={
            "application_status":e.target.value
        }
        fetch(`${baseUrl.baseUrl}trade-in-application/${forms.trade_in_application_id}/update-application-status/`, {
            method,
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${tokenStr}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
        })
        .then(async response => {
            const results = await response.json()
            // setCxSpecialist(e.target.value);
        })
        .catch((error) => {
            console.log('error', error)
        })
    }

    const debounce = (func) => {
        let timeoutId;
        return function (...args) {
            const context = this;
            if (debouncedSearch) clearTimeout(timeoutId);
            // setLoanOfficer([])
            // setIsLoading(true)
            timeoutId = setTimeout(() => {
                timeoutId = null
                func.apply(context, args);
            }, 1000);
        }
    };

    const handleSearchValue = (event) => {
        fetch(`${baseUrl.baseUrlCp}search-partner-contact?partner_id=${formik?.values?.originatorName}&partner_contact_role_id=${partnerContactRoleLoan}&q=${event.target.value}`,
            {
                headers: {
                    "Authorization": `${process.env.REACT_APP_API_KEY}`,
                    'Accept-Encoding': 'gzip, deflate',
                }
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 201 || response.status === 200) {
                    let loanValue = result.filter((item) => item.partner_contact_role === 1 ? item : null)
                    setLoanOfficerItems(loanValue)
                    setIsOpen(true);
                }
                else if (response.status === 403) {
                }
            }
            )
            .catch((error) => {
                console.error(error)
            })
    }
    const handleSearchAgentValue = (event) => {
        fetch(`${baseUrl.baseUrlCp}search-partner-contact?partner_id=${formik?.values?.originatorName}&partner_contact_role_id=${partnerContactRoleRealEstate}&q=${event.target.value}`,
            {
                headers: {
                    "Authorization": `${process.env.REACT_APP_API_KEY}`,
                    'Accept-Encoding': 'gzip, deflate',
                }
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 201 || response.status === 200) {
                    let agentValue = result.filter((item) => item.partner_contact_role === 2 ? item : null)
                    setAgentItems(agentValue)
                    setIsAgentOpen(true);
                }
                else if (response.status === 403) {
                }
            }
            )
            .catch((error) => {
                console.error(error)
            })
    }
    const debouncedSearch = useCallback(
        debounce(handleSearchValue, 200),
        []
    );
    
    const handleChangeLOValue = (newValue) =>{
        if(newValue !== null) {
            loanOfficerItems && loanOfficerItems.map(lo => {
                if (lo.calque_partner_contact_full_name == newValue) {
                    setSelectedLo(lo);
                    setLoStateChange(false);
                    setLoanOfficerState(false);
                    setShowAddButton(false);
                    setConfirmLOButtonDisplay(true);
                    formik.setFieldValue('loanOfficerFullName',lo.calque_partner_contact_full_name)
                    setIsOpen(false)
                }
            })
        }else{
            setShowAddButton(true);
            setConfirmLOButtonDisplay(false);
            formik.setFieldValue('loanOfficerFullName','');
            setSelectedLo(null);
            setLoanOfficerState(true);
            if(editedLo !== null){
                formik.setFieldValue('loanOfficerFirstName', editedLo?.first_name)
                formik.setFieldValue('loanOfficerLastName', editedLo?.last_name)
                formik.setFieldValue('loanOfficerEmail', editedLo?.email)
                formik.setFieldValue('loanOfficerExtension', editedLo?.extension)
                formik.setFieldValue('loanOfficerPhoneNumber', editedLo?.phone_number)
            }else if(forms?.is_loan_officer === true) {
                if (forms.loan_officer === null) {
                    formik.setFieldValue('loanOfficerFirstName', forms?.lo_first_name)
                    formik.setFieldValue('loanOfficerLastName', forms?.lo_last_name)
                    formik.setFieldValue('loanOfficerEmail', forms?.lo_email)
                    formik.setFieldValue('loanOfficerExtension', forms?.lo_extension)
                    formik.setFieldValue('loanOfficerPhoneNumber', forms?.lo_phone_number)
                } else {
                    setConfirmLOButtonDisplay(true)
                    formik.setFieldValue('loanOfficerFirstName', forms?.loan_officer?.first_name ? forms.loan_officer?.first_name : '')
                    formik.setFieldValue('loanOfficerLastName', forms?.loan_officer?.last_name ? forms.loan_officer?.last_name : '')
                    formik.setFieldValue('loanOfficerEmail', forms?.loan_officer?.email ? forms.loan_officer?.email.trim() : '')
                    formik.setFieldValue('loanOfficerExtension', forms?.loan_officer?.extension ? forms.loan_officer?.extension : '')
                    formik.setFieldValue('loanOfficerPhoneNumber', forms?.loan_officer?.phone_number ? forms.loan_officer?.phone_number : '')
                }
            }
            
        }
    }
    const handleChangeAgentValue = (newValue) =>{
        if(newValue !== null) {
            agentItems && agentItems.map(agent => {
                if (agent.calque_partner_contact_full_name == newValue) {
                    setSelectedAgent(agent);
                    setRsaStateChange(false);
                    setRealEstateAgentState(false);
                    setShowAddAgentButton(false)
                    setConfirmRSAButtonDisplay(true);
                    formik.setFieldValue('realEstateAgentFullName',agent.calque_partner_contact_full_name)
                    setIsAgentOpen(false)
                }
            })
        }else{
            setShowAddAgentButton(true);
            setConfirmRSAButtonDisplay(false);
            formik.setFieldValue('realEstateAgentFullName','');
            setSelectedAgent(null);
            setRsaStateChange(true);
            setRealEstateAgentState(true);
            if(editedAgent !== null){
                formik.setFieldValue('realEstateAgentFirstName', editedAgent?.first_name)
                formik.setFieldValue('realEstateAgentLastName', editedAgent?.last_name)
                formik.setFieldValue('realEstateAgentEmail', editedAgent?.email)
                formik.setFieldValue('realEstateAgentExtension', editedAgent?.extension)
                formik.setFieldValue('realEstateAgentPhoneNumber', editedAgent?.phone_number)
            }else if (forms?.is_real_estate_agent === true) {
                if (forms.real_estate_agent === null) {
                    formik.setFieldValue('realEstateAgentFirstName', forms?.agent_first_name)
                    formik.setFieldValue('realEstateAgentLastName', forms?.agent_last_name)
                    formik.setFieldValue('realEstateAgentEmail', forms?.agent_email)
                    formik.setFieldValue('realEstateAgentPhoneNumber', forms?.agent_phone_number)
                    formik.setFieldValue('realEstateAgentExtension', forms?.agent_extension)
                } else {
                    setConfirmRSAButtonDisplay(true)
                    formik.setFieldValue('realEstateAgentFirstName', forms?.real_estate_agent?.first_name ? forms?.real_estate_agent?.first_name : '')
                    formik.setFieldValue('realEstateAgentLastName', forms?.real_estate_agent?.last_name ? forms?.real_estate_agent?.last_name : '')
                    formik.setFieldValue('realEstateAgentEmail', forms?.real_estate_agent?.email ? forms?.real_estate_agent?.email.trim() : '')
                    formik.setFieldValue('realEstateAgentPhoneNumber', forms?.real_estate_agent?.phone_number ? forms?.real_estate_agent?.phone_number : '')
                    formik.setFieldValue('realEstateAgentExtension', forms?.real_estate_agent?.extension ? forms?.real_estate_agent?.extension : '')
                }
            }
        }
    }
    useEffect(() => {
        let tokenStr = localStorage.getItem('access_token')
        let headers = { "Authorization": `Bearer ${tokenStr}` }
        fetch(`${baseUrl.baseUrl}trade-in-application/${forms?.trade_in_application_id}/`,
            {
                headers
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 200 || response.status === 201) {               
                    setNewStatus(result.application_status.application_status_id)
                } else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            })
    },[])
    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (ref.current && !ref.current.contains(e.target)) {
                setIsOpen(false)
                setIsAgentOpen(false)
            }
        }
        document.addEventListener("click", checkIfClickedOutside)
        return () => {
            document.removeEventListener("click", checkIfClickedOutside)
        }
    }, [isOpen,isAgentOpen])
    const handleLoanOfficerMsg = () => {
        return (
            (forms !== undefined && forms.length !== 0 && loStateChange === false) ?
                (forms?.loan_officer === null && forms?.is_loan_officer === true && confirmLOButtonDisplay === false) || (originatorChangeLoConfirm && forms?.is_loan_officer === true && loanOfficerState === true && formik.values.originatorName!=="") ?
                    <div className='loan_officer_edit_heading'>Please edit the submitted Loan Officer by clicking on the link&nbsp;<span className='loan_officer_edit_heading_link' onClick={(e) => handleLoanOfficerEditDetails(e)}>here</span>. Otherwise click the Confirm button if the details are correct.</div>
                    :
                    (forms?.is_loan_officer === true && showAddButton && !isLoAdded) ?
                        <div className='loan_officer_edit_heading'>Please edit the submitted Loan Officer by clicking on the link&nbsp;<span className='loan_officer_edit_heading_link' onClick={(e) => handleLoanOfficerEditDetails(e)}>here</span>.</div> : null
                : null
        )
    }
    const handleRealEstateMsg = () => {
        return (
            (forms !== undefined && forms.length !== 0 && rsaStateChange === false) ?
                (forms?.real_estate_agent === null && forms?.is_real_estate_agent === true && confirmRSAButtonDisplay === false) || (originatorChangeAgentConfirm && forms?.is_real_estate_agent === true && realEstateAgentState === true && formik.values.originatorName!=="") ?
                    <div className='loan_officer_edit_heading'>Please edit the submitted Real Estate Agent by clicking on the link&nbsp;<span className='loan_officer_edit_heading_link' onClick={(e) => handleRealEstateAgentEditDetails(e)}>here</span>. Otherwise click the Confirm button if the details are correct.</div>
                    :
                    (forms?.is_real_estate_agent === true && showAddAgentButton && !isAgentAdded) ?
                        <div className='loan_officer_edit_heading'>Please edit the submitted Real Estate Agent by clicking on the link&nbsp;<span className='loan_officer_edit_heading_link' onClick={(e) => handleRealEstateAgentEditDetails(e)}>here</span>.</div> : null
                : null
        )
    }
    const editValues = () => {
        setLoaderStatus(false)
        setDisplayAddLo(false)
        setDisplayAddLoCheck(true)
        setDisplayAddRSA(false)
        setDisplayAddRSACheck(true)
        setAlterationsAndProblems(forms.existing_property.alterations_and_problems)
        setNewStatus(forms?.application_status?.application_status_id);
        setPrimaryResidence(forms?.existing_property?.is_primary_residence?.toString());
        setIsOwner(forms?.existing_property?.is_owner?.toString());

        if(forms?.existing_property?.defects === null || forms?.existing_property?.defects.length === 0){
            setCheckedBoxes([]);
            setIsDefectsChecked(false);
        }else{
            const defaultCheckedValues = forms?.existing_property?.defects?.map(defect => defect.toString());
            setCheckedBoxes(defaultCheckedValues);
            setIsDefectsChecked(true);
        }

        if (forms?.trade_in_application_id) {
            localStorage.setItem('application_id', forms.trade_in_application_id)
            localStorage.setItem('status_id', forms?.application_status?.application_status_id)
        }

        if (forms?.borrowers['1']?.first_name != null ||
            forms?.borrowers['1']?.email != null ||
            forms?.borrowers['1']?.last_name != null ||
            forms?.borrowers['1']?.phone_no != null) {
            setCoBorrower("yes")
        } else {
            setCoBorrower("no")
        }

        if(forms?.existing_property?.defects?.length > 0){
            setIsDefects("true");
        }

        if (forms?.existing_property?.purchase_date != null ||
            forms?.existing_property?.purchase_price != null ||
            forms?.existing_property?.home_condition?.home_condition != null ||
            forms?.existing_property?.minimum_repair_cost != null ||
            forms?.existing_property?.maximum_repair_cost != null
        ) {
            setPropertyMoreInfo(true)
        } else {
            setPropertyMoreInfo(false)
        }

        if (forms?.loan_officer?.calque_partner_contact_id != null || forms?.is_loan_officer === true) {
            setLoanOfficerName(true)
        } else {
            setLoanOfficerName(false)
        }

        if (forms?.real_estate_agent?.calque_partner_contact_id != null || forms?.is_real_estate_agent === true) {
            setRealEstateAgentName(true)
        } else {
            setRealEstateAgentName(false)
        }

        if (forms?.existing_mortgage?.about_other_loans_or_liens != null && forms?.existing_mortgage?.about_other_loans_or_liens != ""){
            setPropertyLoansInfo('true')
            setLoansInfo('true')
        } else {
            setPropertyLoansInfo('false')
            setLoansInfo('false')
        }

        if ( (forms?.existing_mortgage?.describe_fees != "" && forms?.existing_mortgage?.describe_fees != null ) || forms?.existing_mortgage?.monthly_amount != null){
            setPropertyOtherFeeInfo('true')
            setOtherFeeInfo('true')
        } else {
            setPropertyOtherFeeInfo('false')
            setOtherFeeInfo('false')
        }

        if (forms?.real_estate_agent_full_name === ("" || null || "None" || undefined) && forms?.is_real_estate_agent === false) {
            setRealEstateAgent("no")
        }

        if (forms?.existing_mortgage?.mort1_payoff_amount != null ||
            forms?.existing_mortgage?.mort1_curr_piti_payment != null
        ) {
            setMortgageFirst('true')
            setPropertyFirst("true")
        } else {
            setMortgageFirst('false')
            setPropertyFirst("false")
        }

        if (forms?.existing_mortgage?.mort2_type != null ||
            forms?.existing_mortgage?.mort2_payoff_amount != null ||
            forms?.existing_mortgage?.mort2_curr_piti_payment != null
        ) {
            setMortgageSecond("true")
            setPropertySecond("true")
        }else {
            setMortgageSecond('false')
            setPropertySecond("false")
        }


        if (forms?.existing_property?.takeout_offer_percentage) {
            handletakeOutOffer('no')
        }

        if (forms?.existing_property?.takeout_offer) {
            handletakeOutOffer('yes')
        }

        if (forms?.real_estate_agent?.calque_partner_contact_id || (forms?.real_estate_agent === null && forms?.agent_first_name !== '' && forms?.agent_last_name !== '')) {
            setRealEstateAgent('yes')
        }

        if (forms?.loan_officer?.calque_partner_contact_id || (forms?.loan_officer === null && forms?.lo_first_name !== '' && forms?.lo_last_name !== '')) {
            setLoanOfficer('yes')
        }


        setZipcodeId(forms?.existing_property?.zip_code?.zip_code_id ? forms.existing_property.zip_code.zip_code_id : '')
        setZipCodeValue(forms?.existing_property?.zip_code?.zip_code ? forms.existing_property.zip_code.zip_code : '')

        formik.setFieldValue('applicationID', forms?.trade_in_application_number ? forms.trade_in_application_number : null)
        formik.setFieldValue('createDate', forms?.created_date ? dateFormat(forms.created_date) : null)
        formik.setFieldValue('externalReference', forms?.external_reference_number ? forms.external_reference_number : null)
        formik.setFieldValue('updatedBy', forms?.updated_by?.username ? forms.updated_by.get_user_full_name : null)
        formik.setFieldValue('createdBy', forms?.created_by?.username ? forms.created_by.get_user_full_name : null)
        formik.setFieldValue('originatorName', forms?.originator_name ? forms.originator_name.partner_id : null)
        formik.setFieldValue('is_test_app', forms.is_test_app === true ? setTestApp(true) : setTestApp(false))
        formik.setFieldValue('submitted_to_crm', forms.submitted_to_crm === true ? setSubmittedCRM(true) : setSubmittedCRM(false))
        if (forms?.is_loan_officer === true) {
            if (forms.loan_officer === null) {
                formik.setFieldValue('loanOfficerFirstName', forms?.lo_first_name)
                formik.setFieldValue('loanOfficerLastName', forms?.lo_last_name)
                formik.setFieldValue('loanOfficerEmail', forms?.lo_email)
                formik.setFieldValue('loanOfficerExtension', forms?.lo_extension)
                formik.setFieldValue('loanOfficerPhoneNumber', forms?.lo_phone_number)
            } else {
                setConfirmLOButtonDisplay(true)
                formik.setFieldValue('loanOfficerFirstName', forms?.loan_officer?.first_name ? forms.loan_officer?.first_name : '')
                formik.setFieldValue('loanOfficerLastName', forms?.loan_officer?.last_name ? forms.loan_officer?.last_name : '')
                formik.setFieldValue('loanOfficerEmail', forms?.loan_officer?.email ? forms.loan_officer?.email.trim() : '')
                formik.setFieldValue('loanOfficerExtension', forms?.loan_officer?.extension ? forms.loan_officer?.extension : '')
                formik.setFieldValue('loanOfficerPhoneNumber', forms?.loan_officer?.phone_number ? forms.loan_officer?.phone_number : '')
            }
        }
        formik.setFieldValue('updatedDate', forms?.updated_date ? dateFormat(forms.updated_date) : null)
        if (forms?.is_real_estate_agent === true) {
            setRealEstateAgent('yes')
            if (forms.real_estate_agent === null) {
                formik.setFieldValue('realEstateAgentFirstName', forms?.agent_first_name)
                formik.setFieldValue('realEstateAgentLastName', forms?.agent_last_name)
                formik.setFieldValue('realEstateAgentEmail', forms?.agent_email)
                formik.setFieldValue('realEstateAgentPhoneNumber', forms?.agent_phone_number)
                formik.setFieldValue('realEstateAgentExtension', forms?.agent_extension)
            } else {
                setConfirmRSAButtonDisplay(true)
                formik.setFieldValue('realEstateAgentFirstName', forms?.real_estate_agent?.first_name ? forms?.real_estate_agent?.first_name : '')
                formik.setFieldValue('realEstateAgentLastName', forms?.real_estate_agent?.last_name ? forms?.real_estate_agent?.last_name : '')
                formik.setFieldValue('realEstateAgentEmail', forms?.real_estate_agent?.email ? forms?.real_estate_agent?.email.trim() : '')
                formik.setFieldValue('realEstateAgentPhoneNumber', forms?.real_estate_agent?.phone_number ? forms?.real_estate_agent?.phone_number : '')
                formik.setFieldValue('realEstateAgentExtension', forms?.real_estate_agent?.extension ? forms?.real_estate_agent?.extension : '')
            }
        }
        formik.setFieldValue('is_loan_officer', forms?.is_loan_officer === false ? setLoanOfficer('no') : setLoanOfficer('yes'))
        formik.setFieldValue('is_real_estate_agent', forms?.is_real_estate_agent === false ? setRealEstateAgent('no') : setRealEstateAgent('yes'))
        if (forms.borrowers) {
            formik.setFieldValue('borrowerFirstName', forms.borrowers['0']?.first_name ? forms.borrowers['0'].first_name : null)
            formik.setFieldValue('borrowerEmail', forms.borrowers['0']?.email ? forms.borrowers['0'].email.trim() : null)
            formik.setFieldValue('borrowerLastName', forms.borrowers['0']?.last_name ? forms.borrowers['0'].last_name : null)
            formik.setFieldValue('borrowerPhoneNumber', forms.borrowers['0']?.phone_no ? forms.borrowers['0'].phone_no : '')
            formik.setFieldValue('homeOwnerExtension', forms.borrowers['0']?.extension ? forms.borrowers['0'].extension : null)

            formik.setFieldValue('coBorrowerFirstName', forms.borrowers['1']?.first_name ? forms.borrowers['1'].first_name : null)
            formik.setFieldValue('coBorrowerEmail', forms.borrowers['1']?.email ? forms.borrowers['1'].email.trim() : null)
            formik.setFieldValue('coBorrowerLastName', forms.borrowers['1']?.last_name ? forms.borrowers['1'].last_name : null)
            formik.setFieldValue('coBorrowerPhoneNumber', forms.borrowers['1']?.phone_no ? forms.borrowers['1'].phone_no : '')
            formik.setFieldValue('additionalHomeOwnerExtension', forms.borrowers['1']?.extension ? forms.borrowers['1'].extension : null)
        }
        formik.setFieldValue('propertyInfoStreet', forms?.existing_property?.street_address ? forms.existing_property.street_address : null)
        formik.setFieldValue('propertyInfoCity', forms?.existing_property?.city ? forms.existing_property.city : null)
        formik.setFieldValue('propertyInfoCounty', forms?.existing_property?.county?.county_id ? forms.existing_property.county.county_id : null)
        formik.setFieldValue('propertyInfoState', forms?.existing_property?.state?.state_id ? forms.existing_property.state.state_id : null)
        formik.setFieldValue('propertyInfoProType', forms?.existing_property?.property_type?.property_type_id ? forms.existing_property.property_type.property_type_id : null)
        formik.setFieldValue('septicSystem', forms?.existing_property?.does_home_have_a_septic_system === true ? 'true' : forms.existing_property.does_home_have_a_septic_system === false ? 'false' : null)
        formik.setFieldValue('propertyInfoPurchase', forms?.existing_property?.purchase_date ? moment(forms.existing_property.purchase_date).format('MM/DD/YYYY') : null)
        formik.setFieldValue('propertyInfoPrice', forms?.existing_property?.purchase_price ? forms.existing_property.purchase_price : null)
        formik.setFieldValue('ownerEstValue',forms?.existing_property?.owner_estimated_home_value ? forms.existing_property.owner_estimated_home_value : null)
        formik.setFieldValue('equity_unlock_requested', forms?.existing_property?.equity_unlock_requested ? forms?.existing_property?.equity_unlock_requested : null)
        formik.setFieldValue('nonOwnerComments',forms?.existing_property?.non_owner_comments ? forms.existing_property.non_owner_comments:null)
        formik.setFieldValue('primaryResidenceComments',forms?.existing_property?.non_resident_comments ? forms.existing_property.non_resident_comments:null)

        formik.setFieldValue('estMortgage1Balance', forms?.existing_mortgage?.mort1_payoff_amount ? forms.existing_mortgage.mort1_payoff_amount : null)
        formik.setFieldValue('mortgageInfoTax', forms?.existing_property?.tax_escrow_flag ? forms.existing_property.tax_escrow_flag.toString() : 'false')
        formik.setFieldValue('mort1MonthlyPayment',forms?.existing_mortgage?.mort1_curr_piti_payment ? forms.existing_mortgage.mort1_curr_piti_payment:null)
        formik.setFieldValue('mortgageInfoInsurance', forms?.existing_property?.insurance_escrow_flag ? forms.existing_property.insurance_escrow_flag.toString() : 'false')
        formik.setFieldValue('mortgageInfoSecondLoanType', forms?.existing_mortgage?.mort2_type?.mortgage_type_id ? forms.existing_mortgage.mort2_type.mortgage_type_id : null)
        formik.setFieldValue('estMortgage2Balance', forms?.existing_mortgage?.mort2_payoff_amount ? forms.existing_mortgage.mort2_payoff_amount : null)
        formik.setFieldValue('mort2MonthlyPayment',forms?.existing_mortgage?.mort2_curr_piti_payment ? forms.existing_mortgage.mort2_curr_piti_payment:null)
        formik.setFieldValue('mort_other_loans',forms?.existing_mortgage?.about_other_loans_or_liens ? forms.existing_mortgage.about_other_loans_or_liens:null)
        formik.setFieldValue('mortDescribeFees',forms?.existing_mortgage?.describe_fees ? forms.existing_mortgage.describe_fees:null)
        formik.setFieldValue('mortOtherFeesMonthlyAmount',forms?.existing_mortgage?.monthly_amount ? forms.existing_mortgage.monthly_amount:null)

        formik.setFieldValue('homeCondition',forms?.existing_property?.home_condition ? forms.existing_property.home_condition.home_condition_id : null);
        formik.setFieldValue('yearBuilt',forms?.existing_property?.house_built_year ? forms.existing_property.house_built_year : null);
        formik.setFieldValue('aboveGradeSqft',forms?.existing_property?.property_in_sqft ? forms.existing_property.property_in_sqft : null);
        formik.setFieldValue('biggestConcern',forms?.existing_property?.reason_for_sale ? forms.existing_property.reason_for_sale : null);
        formik.setFieldValue('readyToSell',forms?.existing_property?.ready_for_selling ? forms.existing_property.ready_for_selling : null);
        formik.setFieldValue('maxHomePrice',forms?.existing_property?.new_home_purchase_price ? forms.existing_property.new_home_purchase_price : null);
        formik.setFieldValue('hearAboutUs',forms?.existing_property?.how_did_you_hear_about_us ? forms.existing_property.how_did_you_hear_about_us : null);
        formik.setFieldValue('condoUnitNum',forms?.existing_property?.condo_unit_number ? forms.existing_property.condo_unit_number : '');
        formik.setFieldValue('condoAssocName',forms?.existing_property?.condo_association_name? forms.existing_property.condo_association_name : '');

        setTimeout(()=>{
            formik.setFieldValue('calqueFee', forms?.calque_fee ? forms.calque_fee : null)
            formik.setFieldValue('administrative_fee', forms?.administrative_fee ? forms.administrative_fee : null)
        },[500])
        
        formik.setFieldValue('takeOutPercentage', forms?.existing_property?.takeout_offer_percentage ? forms.existing_property.takeout_offer_percentage : null)
        formik.setFieldValue('takeOutBuyer', forms?.existing_property?.takeout_offer ? forms.existing_property.takeout_offer : null)
        setLoaderStatus(false)

        if (forms?.trade_in_application_id) {
            setAvmValuationManual(forms?.is_entry_type_manual)

            if (forms?.loan_officer !== null && forms?.lo_first_name !== '' && forms?.lo_last_name !== '') {
                setConfirmLOButtonDisplay(true)
            }

            if (forms?.real_estate_agent !== null && forms?.agent_first_name !== '' && forms?.agent_last_name !== '') {
                setConfirmRSAButtonDisplay(true)
            }
        }
        setTimeout(() => {
            setIsComponentMounted(true);
        },2000);
    }
    const getApplicationData = (id) => {
        let tokenStr = localStorage.getItem('access_token')
        let headers = { "Authorization": `Bearer ${tokenStr}` }
        fetch(`${baseUrl.baseUrl}trade-in-application/${id}/`,
            {
                headers
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 200 || response.status === 201) {
                    setForms(result)                   
                    setNewStatus(result.application_status.application_status_id)
                } else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            })
    }
    const validate = values => {
        let errors = {};
        let firstDate = new Date();
        let secondDate = new Date(values.propertyInfoPurchase);
        const regex = /^\d{2}\/\d{2}\/\d{4}$/;
        if (!values.originatorName) {
            errors.originatorName = "Please fill mandatory(*) fields."
        } else if(loanOfficer === 'yes' && (!values.loanOfficerFullName || values.loanOfficerFullName) && (!values.loanOfficerFirstName || !values.loanOfficerLastName || values.loanOfficerEmail === '') ||
                  loanOfficer === 'yes' && !values.loanOfficerFirstName ||
                  loanOfficer === 'yes' && !values.loanOfficerLastName ||
                  loanOfficer === 'yes' && values.loanOfficerEmail === "") {
                  errors.loanOfficerFirstName = "Please fill the Loan Officer section mandatory(*) fields."
        } else if(realEstateAgent === 'yes' && (!values.realEstateAgentFullName || values.realEstateAgentFullName) && (!values.realEstateAgentFirstName || !values.realEstateAgentFirstName) ||
                  realEstateAgent === 'yes' && !values.realEstateAgentFullName && !values.realEstateAgentFirstName ||
                  realEstateAgent === 'yes' && !values.realEstateAgentFullName && !values.realEstateAgentLastName) {
                  errors.realEstateAgentFirstName = "Please fill the Real Estate Agent section mandatory(*) fields."
        } 

        if (!values.borrowerFirstName || values.borrowerFirstName.trim() === "" ||
            !values.borrowerEmail || values.borrowerEmail.trim() === "" ||
            !values.borrowerLastName || values.borrowerLastName.trim() === "" ||
            !values.borrowerPhoneNumber || values.borrowerPhoneNumber.trim() === ''
        ) {
            errors.borrowerFirstName = "Please fill the Home Owner section mandatory(*) fields."
        } else if (coBorrower === 'yes' && !values.coBorrowerFirstName ||
            coBorrower === 'yes' && !values.coBorrowerEmail ||
            coBorrower === 'yes' && !values.coBorrowerLastName
        ) {
            errors.borrowerFirstName = "Please fill the Additional Home Owner section mandatory(*) fields."
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.borrowerEmail.trim()) && (errors.borrowerFirstName || values.borrowerLastName) !== "Please fill the borrower section mandatory(*) fields.") {
            errors.borrowerEmail = 'Invalid Home Owner email address';
        }
        else if (coBorrower === 'yes' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.coBorrowerEmail) && (coBorrower === 'yes' && errors.coBorrowerFirstName || values.coBorrowerLastName) !== "Please fill the co borrower section mandatory(*) fields.") {
            errors.coBorrowerEmail = 'Invalid Additional Home Owner email address';
        }
        if (!values.propertyInfoStreet || values.propertyInfoStreet.trim() === "" ||
            zipCodeValue === '' ||
            !values.propertyInfoCity || values.propertyInfoCity.trim() === "" ||
            !values.propertyInfoCounty ||
            !values.propertyInfoState ||
            !values.propertyInfoProType ||
            !values.ownerEstValue ||
            isOwner === '' ||
            (isOwner === "false" && values.nonOwnerComments === '') ||
            (isOwner === "true" && primaryResidence === '')
        ) {
            errors.propertyInfoStreet = "Please fill the existing property section mandatory(*) fields."
        }
        if (secondDate > firstDate) {
            errors.propertyInfoStreet = "Date should not be in the future"
        } 
        if(!values.propertyInfoPurchase === null || values.propertyInfoPurchase === 'Invalid date') {
            if (!regex.test(values.propertyInfoPurchase)) {
                errors.propertyInfoStreet = "Invalid Date Format"
            }
        }
        if (mortgageFirst === 'true') {
            if (!values.estMortgage1Balance || !values.mort1MonthlyPayment) {
                errors.estMortgage1Balance = "Please fill the existing property section mandatory(*) fields."
            }
        }

        if (loansInfo === 'true' && (values.mort_other_loans === '' || values.mort_other_loans === null)){
            errors.mort_other_loans = "Please fill the existing property section mandatory(*) fields."
        }

        if (otherFeeInfo === 'true' && values.mortDescribeFees === null && values.mortOtherFeesMonthlyAmount === null){
            errors.mortDescribeFees = "Please fill the existing property section mandatory(*) fields."
        }

        if (takeOutOffer === 'no' && !values.takeOutPercentage || !values.calqueFee || !values.administrative_fee) {
            errors.calqueFee = "Please fill the take out section mandatory(*) fields."
        }

        if (takeOutOffer === 'yes' && !values.takeOutBuyer || !values.calqueFee || !values.administrative_fee) {
            errors.calqueFee = "Please fill the take out section mandatory(*) fields."
        }

        if (values.yearBuilt && (values.yearBuilt.toString().length !== 4 || isNaN(values.yearBuilt))) {
            errors.yearBuilt = `Year must contains exactly 4 digits and less than ${currentTime.getFullYear()+1}`;
        } else if (values.yearBuilt) {
            const yearNumber = parseInt(values.yearBuilt, 10);
            if (!isNaN(yearNumber) && (yearNumber < 1800 || yearNumber > currentTime.getFullYear()+1))  {
                errors.yearBuilt = `Year must be between 1800 and ${currentTime.getFullYear()+1}`;
            }
        }

        return errors
    }

    const handleCheckboxChange = (event) => {
        const checkboxValue = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
            setCheckedBoxes(prevCheckedBoxes => [...prevCheckedBoxes, checkboxValue]);
        } else {
            setCheckedBoxes(prevCheckedBoxes => prevCheckedBoxes.filter(id => id !== checkboxValue));
        }
    };

    const handleRadioChange = (conditionName, value) => {
        setAlterationsAndProblems(prevState => ({
            ...prevState,
            [conditionName]: value === "true" 
        }));
    };

    const restrictNumber = (e) => {
        var deleteKeyCode = 8;
        var backspaceKeyCode = 46;
        if ((e.which >= 48 && e.which <= 57) ||
            (e.which >= 96 && e.which <= 105) ||
            e.which === deleteKeyCode ||
            e.which === backspaceKeyCode || e.which === 37 || e.which === 39) {
            return true;
        } else {
            e.preventDefault();
        }
    }
    const restrictDecimal = (e, id) => {
        var deleteKeyCode = 8;
        var backspaceKeyCode = 46;
        if ((e.which >= 48 && e.which <= 57) ||
            (e.which >= 96 && e.which <= 105) ||
            e.which === deleteKeyCode ||
            e.which === backspaceKeyCode || e.which === 190 || e.which === 37 || e.which === 39) {
            var valuemortgageInfoCumulative = document.getElementById(id);
            if (valuemortgageInfoCumulative.value.indexOf('.') !== -1 && e.which === 190) {
                e.preventDefault();
            }
        } else {
            e.preventDefault();
        }
    }
    const handleRange = (e) => {
        if (e.target.value > 100) {
            return e.target.value = 100
        } else if (e.target.value < 0) {
            return e.target.value = 0
        }
    }
    const onSubmit = values => {
        setMgpLoader(true)
        setDisplayPropertyError(false)
        setResponseLOEditAdd(false)
        setResponseRSAEditAdd(false)
        let provider = valuationProvider && valuationProvider?.valuation_provider?.filter(prov => displayProvider == prov?.valuation_provider)
        let tokenStr = localStorage.getItem('access_token')
        let body = {
            borrower: {
                first_name: values.borrowerFirstName,
                last_name: values.borrowerLastName,
                email: values.borrowerEmail,
                phone_no: values.borrowerPhoneNumber,
                extension: values.homeOwnerExtension === null || values.homeOwnerExtension === '' ? null : values.homeOwnerExtension 
            },
            existing_property: {
                valuation_source: forms?.existing_property?.valuation_source ? forms?.existing_property?.valuation_source?.valuation_source_id : 1,
                street_address: values.propertyInfoStreet,
                city: values.propertyInfoCity,
                state: values.propertyInfoState,
                county: values.propertyInfoCounty,
                zip_code: zipcodeId,
                property_type: values.propertyInfoProType,
                owner_estimated_home_value: values.ownerEstValue === '.' ? 0 : parseFloat(values.ownerEstValue?.replace(/,/g, '')),
                equity_unlock_requested: values.equity_unlock_requested === '.' ? 0 : parseFloat(values?.equity_unlock_requested?.replace(/,/g, '')),
                estimated_home_value: forms?.existing_property?.estimated_home_value ? parseFloat(forms.existing_property.estimated_home_value.replace(/,/g, '')) : parseFloat(values.ownerEstValue.replace(/,/g, '')),
                home_condition: values.homeCondition,
                house_built_year: values.yearBuilt? values.yearBuilt:0,
                property_in_sqft: parseFloat(values.aboveGradeSqft?.replace(/,/g, '')),
                tax_escrow_flag:values.mortgageInfoTax,
                insurance_escrow_flag:values.mortgageInfoInsurance,
                reason_for_sale: values.biggestConcern,
                ready_for_selling: values.readyToSell,
                new_home_purchase_price: parseFloat(values.maxHomePrice?.replace(/,/g, '')),
                how_did_you_hear_about_us: values.hearAboutUs,
                owner_occupied: isOwner,
                purchase_date: (values.propertyInfoPurchase === null || values.propertyInfoPurchase === '') ? null : moment(values.propertyInfoPurchase).format('YYYY-MM-DD'),
                purchase_price: (values.propertyInfoPrice === null || values.propertyInfoPrice === '') ? null : parseFloat(values.propertyInfoPrice?.replace(/,/g, '')),
                takeout_offer: (values.takeOutBuyer === '' || values.takeOutBuyer === null) ? null : parseFloat(values.takeOutBuyer?.replace(/,/g, '')),
                takeout_offer_percentage: (values.takeOutPercentage === '' || values.takeOutPercentage === null) ? null : values.takeOutPercentage,
                defects: checkedBoxes.length > 0 ? checkedBoxes.map((items) => Number(items)) : [],
                alterations_and_problems: alterationsAndProblems,
                does_home_have_a_septic_system: values.septicSystem,
                is_owner: isOwner,
                non_owner_comments: values.nonOwnerComments,
                is_primary_residence:primaryResidence,
                non_resident_comments:values.primaryResidenceComments,
                condo_unit_number: (values.condoUnitNum === '' || values.condoUnitNum === null) ? null : values.condoUnitNum.trim(),
                condo_association_name: (values.condoAssocName === '' || values.condoAssocName === null) ? null : values.condoAssocName.trim()
            },
            external_reference_number: values.externalReference,
            originator_name: values.originatorName,
            calque_fee: values.calqueFee,
            administrative_fee: values.administrative_fee.toString().replace(/,/g, ''),
            is_loan_officer: loanOfficerName,
            is_real_estate_agent: realEstateAgentName,
            loan_officer: originatorChangeLoConfirm ? null : selectedLo ? parseInt(selectedLo.calque_partner_contact_id) : forms?.loan_officer?.calque_partner_contact_id,
            real_estate_agent: originatorChangeAgentConfirm ? null : selectedAgent ? parseInt(selectedAgent.calque_partner_contact_id) : forms?.real_estate_agent?.calque_partner_contact_id,
            is_test_app: isTest !== null ? isTest : testApp,
            submitted_to_crm: submittedCRM,
            is_avm_updated:false,
            is_entry_type_manual:false,
            cx_specialist:props.cxSpecialist,
            application_status:newStatus,
        }
        if (coBorrower === 'yes') {
            body['co_borrower'] = {
                first_name: values.coBorrowerFirstName != '' ? values.coBorrowerFirstName : null,
                last_name: values.coBorrowerLastName != '' ? values.coBorrowerLastName : null,
                email: values.coBorrowerEmail != '' ? values.coBorrowerEmail : null,
                phone_no: values.coBorrowerPhoneNumber != '' ? values.coBorrowerPhoneNumber : '',
                extension: values.additionalHomeOwnerExtension != '' ? values.additionalHomeOwnerExtension : null,
            }
        }

        if (mortgageFirst === 'true' || mortgageFirst === 'false' || mortgageSecond === 'true' || mortgageSecond === 'false' || otherFeeInfo === 'true' || otherFeeInfo === 'false' || loansInfo === 'true' || loansInfo === 'false') {
            body['existing_mortgage'] = {
                mort1_payoff_amount: values.estMortgage1Balance === null ? null : parseFloat(values.estMortgage1Balance.replace(/,/g, '')),
                mort1_curr_piti_payment:values.mort1MonthlyPayment === null ? null : parseFloat(values.mort1MonthlyPayment.replace(/,/g, '')),
                mort2_type: values.mortgageInfoSecondLoanType != '' ? values.mortgageInfoSecondLoanType : null,
                mort2_payoff_amount: values.estMortgage2Balance === null ? null : parseFloat(values.estMortgage2Balance.replace(/,/g, '')),
                mort2_curr_piti_payment: values.mort2MonthlyPayment === null ? null : parseFloat(values.mort2MonthlyPayment.replace(/,/g, '')),
                about_other_loans_or_liens: values.mort_other_loans === '' ? '': values.mort_other_loans,
                describe_fees: values.mortDescribeFees === null ? null : values.mortDescribeFees,
                monthly_amount: values.mortOtherFeesMonthlyAmount === null ? null : parseFloat(values.mortOtherFeesMonthlyAmount.replace(/,/g, '')),
                other_loans_or_liens: loansInfo === "false"? false : true,
                other_fees_or_due: otherFeeInfo === "false"? false : true,
            }
        }
        if (coBorrower === 'yes' && body['co_borrower'].first_name != null || coBorrower === 'yes' && body['co_borrower'].last_name != null || coBorrower === 'yes' && body['co_borrower'].email != null) {
            setCoBorrower("yes")
        }

        if (body.existing_property['purchase_date'] != null || body.existing_property['purchase_price'] != null || body.existing_property['home_condition'] != null || body.existing_property['minimum_repair_cost'] != null || body.existing_property['maximum_repair_cost'] != null) {
            togglePropertyMoreInfo();
        }

        if (mortgageFirst === 'true') {
            if (body.existing_mortgage['mort1_origin_date'] != null || body.existing_mortgage['mort1_mortgage_program'] != null || body.existing_mortgage['mort1_original_loan_amount'] != null || body.existing_mortgage['mort1_type'] != null || body.existing_mortgage['mort1_curr_unpaid_principal'] != null || body.existing_mortgage['mort1_curr_loan_bal'] != null || body.existing_mortgage['mort1_purpose'] != null || body.existing_mortgage['mort1_rate'] || body.existing_mortgage['mort1_term_months'] != null) {
                toggleMortgageMoreInfo();
            }
        }

        let url = '', method = '';

        if (forms?.trade_in_application_id) {
            url = `${baseUrl.baseUrl}trade-in-application/${forms.trade_in_application_id}/`
            method = 'PATCH'
        } else {
            url = `${baseUrl.baseUrl}trade-in-application/`
            method = 'POST'
        }
        fetch(url, {
            method,
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${tokenStr}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
        })
            .then(async response => {
                const results = await response.json()
                if (response.status === 201 || response.status === 200) {
                    setDisplayAddLo(false)
                    setDisplayAddRSA(false)
                    localStorage.setItem("isCreate",false);
                    setDetail(results.message);
                    props.setIscalculated(false);
                    setIsLoAdded(false);
                    setIsAgentAdded(false);
                    setSeverityType("success");
                    setOpenSnackbar(true);
                    if (isOriginatorChanged){
                        setIsSaved(true);
                    }
                    setForms(results.trade_in_application)
                    formik.setFieldValue("loanOfficerFullName",'');
                    formik.setFieldValue("realEstateAgentFullName",'');
                    if(checkedBoxes.length === 0 && results?.trade_in_application.existing_property?.defects.length === 0){
                        setIsDefects(false);
                    }
                    await sleep(1000);
                    setOpenSnackbar(false);
                    setShowAddButton(true);
                    setShowAddAgentButton(true);
                    setDisplayPropertyError(true)
                    setIsTest(null);
                    setMgpLoader(false)
                    isAvmUpdatedValue = false
                }
                else {
                    if (response.status === 403) {
                        localStorage.setItem('access_token', '')
                        props.history.push('/');
                    }
                    if (results.detail) {
                        setDetail(results.detail)
                        setSeverityType("error");
                        setOpenSnackbar(true);
                        await sleep(1000);
                        setOpenSnackbar(false);
                        setMgpLoader(false)
                    }
                    else {
                        setDetail("something went wrong")
                        setSeverityType("error");
                        setOpenSnackbar(true);
                        await sleep(1000);
                        setOpenSnackbar(false);
                        setMgpLoader(false)
                    }
                }
            })
            .catch((error) => {
                console.log('error', error)
            })
    }
    const formik = useFormik({
        initialValues,
        validate,
        onSubmit,
    });


    const handleZipcode = (event) => {
        var max_length = 5;
        if (event.length > max_length) {
            event = event.substr(0, max_length);
        }
        setZipCodeValue(event)
        var tokenStr = localStorage.getItem('access_token')

        if (event.length === 5) {
            fetch(`${baseUrl.baseUrl}get-zip-codes/?search=${event}`,
                {
                    headers: { "Authorization": `Bearer ${tokenStr}` }
                })
                .then(async response => {
                    const result = await response.json()
                    var res = result;
                    if (res.length > 0) {
                        setZipcodeId(res[0].zip_code_id)
                        setZipCodeValue(res[0].zip_code)
                        formik.setFieldValue('propertyInfoState', res[0].state.state_id)
                        formik.setFieldValue('propertyInfoCity', res[0].city)
                        formik.setFieldValue('propertyInfoCounty', res[0].county.county_id)

                    } else if (response.status === 403) {
                        localStorage.setItem('access_token', '')
                        props.history.push('/');
                    } else {
                        setZipCodeValue('')
                        formik.setFieldValue('propertyInfoState', '')
                        formik.setFieldValue('propertyInfoCity', '')
                        formik.setFieldValue('propertyInfoCounty', '')
                    }
                });
        }
    }
    const togglePropertyMoreInfo = () => {
        setPropertyMoreInfo(!propertyMoreInfo)
    }
    const toggleMortgageMoreInfo = () => {
        setMortgageMoreInfo(!mortgageMoreInfo)
    }
    const handleCoBorrowerChange = (event) => {
        setCoBorrower(event)
        setRadioError(event)
        if (event === 'no') {
            formik.setFieldValue('coBorrowerFirstName', '')
            formik.setFieldValue('coBorrowerEmail', '')
            formik.setFieldValue('coBorrowerLastName', '')
            formik.setFieldValue('coBorrowerPhoneNumber', '')
            formik.setFieldValue('additionalHomeOwnerExtension', null)
        }
    }
    const handleRealEstateAgent = (event) => {
        if (event === 'no') {
            setRealEstateAgent(event)
            setRealEstateAgentName(false)
            setAgentAddDisable(false);
            setCheckRsaResEdit(null)
            setIsClickedAgentNo(true)
            setRsaStateChange(true)
            formik.setFieldValue('realEstateAgentFullName', '')
            formik.setFieldValue('realEstateAgentFirstName', '')
            formik.setFieldValue('realEstateAgentLastName', '')
            formik.setFieldValue('realEstateAgentEmail', '')
            formik.setFieldValue('realEstateAgentPhoneNumber', '')
            formik.setFieldValue('realEstateAgentExtension', null)
        } else {
            setRealEstateAgent(event)
            setRealEstateAgentName(true)
        }
    }
    const handleLoanOfficer = (event) => {
        if (event === 'no') {
            setLoanOfficer(event)
            setLoAddDisable(false);
            setLoanOfficerName(false)
            setCheckLoResEdit(null)
            setIsClickedLoNo(true);
            setLoStateChange(true);
            formik.setFieldValue('loanOfficerFullName', '')
            formik.setFieldValue('loanOfficerFirstName', '')
            formik.setFieldValue('loanOfficerLastName', '')
            formik.setFieldValue('loanOfficerEmail', '')
            formik.setFieldValue('loanOfficerPhoneNumber', '')
            formik.setFieldValue('loanOfficerExtension', '')
        } else {
            setLoanOfficer(event)
            setLoanOfficerName(true)
        }
    }
    const handleTestApp = (event) => {
        if (event === 'true') {
            setTestApp(true)
        } else if (event === 'false') {
            setTestApp(false)
        }
    }
    const handleSubmittedCRM = (event) => {
        if (event === 'true') {
            setSubmittedCRM(true)
        } else if (event === 'false') {
            setSubmittedCRM(false)
        }
    }
    const handleMortgageFirst = (event) => {
        setMortgageFirst(event)
        if (event === 'false') {
            setMortgageMoreInfo(false);
            formik.setFieldValue('estMortgage1Balance', '')
            formik.setFieldValue('mortgageInfoTax', false)
            formik.setFieldValue('mort1MonthlyPayment', "")
            formik.setFieldValue('mortgageInfoInsurance', false)
        }
    }
    const handleMortgageSecond = (event) => {
        setMortgageSecond(event)
        if (event === 'false') {
            formik.setFieldValue('mortgageInfoSecondLoanType', "")
            formik.setFieldValue('estMortgage2Balance', "")
            formik.setFieldValue('mort2MonthlyPayment', '')
        }
    }
    const handleLoansInfo = (event) => {
        setLoansInfo(event);
        if(event === 'false'){
            formik.setFieldValue('mort_other_loans', '')
        }
    }
    const handleOtherFee = (event) => {
        setOtherFeeInfo(event);
        if (event === 'false'){
            formik.setFieldValue('mortDescribeFees', "")
            formik.setFieldValue('mortOtherFeesMonthlyAmount', "")
        }
    }
    const handleIsDefects = (event) =>{
        setIsDefects(event);
        if(event === "false"){
            setCheckedBoxes([]);
            setIsDefectsChecked(false);
        }else{
            setIsDefectsChecked(true);
        }
    }
    const handlePropertyFirst = (event) => {
        setPropertyFirst(event);
    }
    const handlePropertySecond = (event) => {
        setPropertySecond(event);
    }
    const handlePropertyLoansInfo = (event) => {
        setPropertyLoansInfo(event);
    }
    const handlePropertyOtherFee = (event) => {
        setPropertyOtherFeeInfo(event);
    }
    const handletakeOutOffer = (event) => {
        setTakeOutOffer(event)
        if (event === 'no') {
            formik.setFieldValue('takeOutBuyer', '')
        } else if (event === 'yes') {
            formik.setFieldValue('takeOutPercentage', '')
        }
    }
    const phoneNumberFormat = (event) => {
        let brackets = event.target.value.replace(/[-()+]/g, '');
        let spaces = brackets.split(" ").join("");
        formik.setFieldValue('loanOfficerPhoneNumber', spaces)
    }
    const phoneNumberFormatRealEstate = (event) => {
        let brackets = event.target.value.replace(/[-()+]/g, '');
        let spaces = brackets.split(" ").join("");
        formik.setFieldValue('realEstateAgentPhoneNumber', spaces)
    }

    const handleKeyDown = e => {
        if (e.key === " " && e.target.selectionStart === 0) {
            e.preventDefault();
        }
    };

    const handleLoanOfficerConfirm = () => {
        let tokenStr = localStorage.getItem('access_token')
        let body = {
            first_name: formik.values.loanOfficerFirstName,
            last_name: formik.values.loanOfficerLastName,
            email: formik.values.loanOfficerEmail,
            extension: null,
            phone_number: formik.values.loanOfficerPhoneNumber,
            calque_partner: formik.values.originatorName,
            partner_contact_role: partnerContactRoleLoan,
            trade_in_application_id: forms?.trade_in_application_id
        }
        fetch(`${baseUrl.baseUrl}partner-contact/confirm/`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${tokenStr}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
        })
            .then(async response => {
                const results = await response.json()
                if (response.status === 201 || response.status === 200) {
                    setOriginatorChangeLoConfirm(false);
                    setLoanOfficerState(false);
                    setDetail(results.message);
                    setSeverityType("success");
                    setLoConfirmResults(results?.partner_contact_details);
                    localStorage.setItem('LoName', results?.partner_contact_details?.calque_partner_contact_id)
                    localStorage.setItem('Lofn', results?.partner_contact_details?.first_name)
                    localStorage.setItem('Loln', results?.partner_contact_details?.last_name)
                    localStorage.setItem('Lopn', results?.partner_contact_details?.phone_number)
                    localStorage.setItem('Loextn', results?.partner_contact_details?.extension)
                    localStorage.setItem('Loemail', results?.partner_contact_details?.email)
                    setConfirmLOButtonDisplay(true);
                    setOpenSnackbar(true);
                    await sleep(1000);
                    setOpenSnackbar(false);
                    if (results?.partner_contact_details?.is_active === false) {
                        setIsLoanOfficerActive('No Longer Active')
                    }
                } else {
                    if (response.status === 403) {
                        localStorage.setItem('access_token', '')
                        props.history.push('/');
                    }
                    if (results.detail) {
                        setDetail(results.detail)
                        setSeverityType("error");
                        setOpenSnackbar(true);
                        await sleep(1000);
                        setOpenSnackbar(false);
                    }
                    else {
                        setDetail("something went wrong")
                        setSeverityType("error");
                        setOpenSnackbar(true);
                        await sleep(1000);
                        setOpenSnackbar(false);
                    }
                }
            })
            .catch((error) => {
                console.log('error', error)
            })
    }

    const handleRealEstateAgentConfirm = () => {
        let tokenStr = localStorage.getItem('access_token')
        let body = {
            first_name: formik.values.realEstateAgentFirstName,
            last_name: formik.values.realEstateAgentLastName,
            email: formik.values.realEstateAgentEmail,
            extension: null,
            phone_number: formik.values.realEstateAgentPhoneNumber,
            calque_partner: formik.values.originatorName,
            partner_contact_role: partnerContactRoleRealEstate,
            trade_in_application_id: forms?.trade_in_application_id
        }
        fetch(`${baseUrl.baseUrl}partner-contact/confirm/`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${tokenStr}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
        })
            .then(async response => {
                const results = await response.json()
                if (response.status === 201 || response.status === 200) {
                    setOriginatorChangeAgentConfirm(false);
                    setRealEstateAgentState(false);
                    setDetail(results.message);
                    setSeverityType("success");
                    setAgentConfirmResults(results?.partner_contact_details)
                    setOpenSnackbar(true);
                    localStorage.setItem('RsaName', results?.partner_contact_details?.calque_partner_contact_id)
                    localStorage.setItem('Rsafn', results?.partner_contact_details?.first_name)
                    localStorage.setItem('Rsaln', results?.partner_contact_details?.last_name)
                    localStorage.setItem('Rsapn', results?.partner_contact_details?.phone_number)
                    localStorage.setItem('Rsaextn', results?.partner_contact_details?.extension)
                    localStorage.setItem('Rsaemail', results?.partner_contact_details?.email)
                    setConfirmRSAButtonDisplay(true)
                    await sleep(1000);
                    setOpenSnackbar(false);
                    if (results?.partner_contact_details?.is_active === false) {
                        setIsRealEstateAgentActive('No Longer Active')
                    }
                } else {
                    if (response.status === 403) {
                        localStorage.setItem('access_token', '')
                        props.history.push('/');
                    }
                    if (results.detail) {
                        setDetail(results.detail)
                        setSeverityType("error");
                        setOpenSnackbar(true);
                        await sleep(1000);
                        setOpenSnackbar(false);
                    }
                    else {
                        setDetail("something went wrong")
                        setSeverityType("error");
                        setOpenSnackbar(true);
                        await sleep(1000);
                        setOpenSnackbar(false);
                    }
                }
            })
            .catch((error) => {
                console.log('error', error)
            })
    }
    const handleNutshell = () => {
        setNutshellAPILoader(true)
        let tokenStr = localStorage.getItem('access_token')
        fetch(`${baseUrl.baseUrl}trade-in-application/${forms?.trade_in_application_id}/sync-with-nutshell/`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${tokenStr}`,
                'Content-Type': 'application/json'
            },
        })
            .then(async response => {
                const results = await response.json()
                if (response.status === 201 || response.status === 200) {
                    setDetail(results.message);
                    setSeverityType("success");
                    setOpenSnackbar(true);
                    await sleep(1000);
                    setOpenSnackbar(false);
                    setNutshellAPILoader(false)
                    setSubmittedCRM(true);
                } else {
                    if (response.status === 403) {
                        localStorage.setItem('access_token', '')
                        props.history.push('/');
                        setNutshellAPILoader(false)
                    }
                    if (results.detail) {
                        setDetail(results.detail)
                        setSeverityType("error");
                        setOpenSnackbar(true);
                        await sleep(1000);
                        setOpenSnackbar(false);
                        setNutshellAPILoader(false)
                    }
                    else {
                        setDetail("something went wrong")
                        setSeverityType("error");
                        setOpenSnackbar(true);
                        await sleep(1000);
                        setOpenSnackbar(false);
                        setNutshellAPILoader(false)
                    }
                }
            })
            .catch((error) => {
                console.log('error', error)
            })
    }
    const handleLOChange = (e) => {
        if(e.target.value !== "None"){
            setLoAddDisable(true);
        }else{
            setLoAddDisable(false);
        }
        setResponseLOEditAdd(true)
        setDisplayAddLoCheck(false)
        localStorage.setItem('LoName', null)
        localStorage.setItem('Lofn', null)
        localStorage.setItem('Loln', null)
        localStorage.setItem('Lopn', null)
        localStorage.setItem('Loextn', null)
        localStorage.setItem('Loemail', null)
    }
    const handleRSAChange = (e) => {
        if(e.target.value !== "None"){
            setAgentAddDisable(true);
        }else{
            setAgentAddDisable(false);
        }
        setResponseRSAEditAdd(true)
        setDisplayAddRSACheck(false)
        localStorage.setItem('RsaName', null)
        localStorage.setItem('Rsafn', null)
        localStorage.setItem('Rsaln', null)
        localStorage.setItem('Rsapn', null)
        localStorage.setItem('Rsaextn', null)
        localStorage.setItem('Rsaemail', null)
    }

    useEffect(() => {
        let localID = localStorage.getItem('application_id');
        let tokenStr = localStorage.getItem('access_token')
        let headers = { "Authorization": `Bearer ${tokenStr}` }

        if (forms && forms.length !== 0) {
            setLoaderStatus(true)
        }

        if (forms && forms.length !== 0) {
            editValues();
        } else if (forms && forms.length === 0 && localID !== null) {
            getApplicationData(localID);
        }

        fetch(`${baseUrl.baseUrl}get-county-list/`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${tokenStr}`,
                    'Content-Type': 'application/json'
                },
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 200 || response.status === 201) {
                    setCountyList(result)
                }
                else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            })

        fetch(`${baseUrl.baseUrlCp}partner/`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${tokenStr}`,
                    'Content-Type': 'application/json'
                },
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 200 || response.status === 201) {
                    setOriginatorNames(result)
                    result && result.map((res) => {
                        if (res.partner_name === "calque" || res.partner_name === "Calque") {
                            setOriginatorNameDefaultValue(res.partner_id)
                        }
                    })

                }
                else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            })

        fetch(`${baseUrl.baseUrl}get-states/`,
            {
                headers
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 200 || response.status === 201) {
                    setStateList(result)
                }
                else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            })
        fetch(`${baseUrl.baseUrl}get-property-type/`,
            {
                headers
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 200 || response.status === 201) {
                    setPropertyTypeList(result)
                }
                else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            })
        fetch(`${baseUrl.baseUrl}get-valuation-source-details/`,
            {
                headers
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 200 || response.status === 201) {
                    setValidationSource(result.result)
                }
                else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            })
        fetch(`${baseUrl.baseUrlCp}get-property-defects/`,
            {
                headers
            })
            .then(async response => {
              const result = await response.json();
              if (response.status === 201 || response.status === 200) {
                setDefectsList(result)
              }
              else if (response.status === 403) {
              }
            }
            )
            .catch((error) => {
              console.error('error message', error)
            })
        fetch(`${baseUrl.baseUrlCp}get-property-condition-details/`,
            {
                headers
            })
            .then(async response => {
              const result = await response.json();
              if (response.status === 201 || response.status === 200) {
                setConditionList(result)
              }
              else if (response.status === 403) {
              }
            }
            )
            .catch((error) => {
              console.error('error message', error)
            })    
        fetch(`${baseUrl.baseUrl}get-home-conditions/home_condition_id`,
            {
                headers
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 200 || response.status === 201) {
                    setPropertyHome(result)
                }
                else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            })
        fetch(`${baseUrl.baseUrlCp}get-how-did-you-hear-about-us-options/`,
            {
                headers
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 200 || response.status === 201) {
                    setHearAboutUs(result)
                }
                else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            })
        fetch(`${baseUrl.baseUrlCp}get_reason_for_sale/`,
            {
                headers
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 200 || response.status === 201) {
                    setConcern(result)
                }
                else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            })
        fetch(`${baseUrl.baseUrlCp}get_ready_to_sell/`,
            {
                headers
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 200 || response.status === 201) {
                    setSellingTimeFrame(result)
                }
                else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            })
        fetch(`${baseUrl.baseUrl}get-mortgage-program/`,
            {
                headers
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 200 || response.status === 201) {
                    setLoanProgram(result)
                }
                else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            })
        fetch(`${baseUrl.baseUrl}get-mortgage-type/`,
            {
                headers
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 200 || response.status === 201) {
                    setLoanType(result)
                }
                else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            })
        fetch(`${baseUrl.baseUrl}get-mortgage-purpose/`,
            {
                headers
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 200 || response.status === 201) {
                    setLoanPurpose(result)
                }
                else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            })
        fetch(`${baseUrl.baseUrl}get-partner-contact-roles/`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${tokenStr}`,
                    'Content-Type': 'application/json'
                },
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 200 || response.status === 201) {
                    result && result.map((res) => {
                        if ((res?.partner_contact_role_name).toLowerCase() === "loan officer") {
                            setPartnerContactRoleLoan(res?.partner_contact_role_id)
                        }
                        if ((res?.partner_contact_role_name).toLowerCase() === "real estate agent") {
                            setPartnerContactRoleRealEstate(res?.partner_contact_role_id)
                        }
                    })
                }
                else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            })
        if (forms?.existing_property?.street_address || forms?.existing_property?.zip_code || forms?.existing_property?.city || forms?.existing_property?.county || forms?.existing_property?.state) {
            setAddressAVMValidate([{
                street_address: forms?.existing_property?.street_address,
                zip_code: forms?.existing_property?.zip_code !== null ? forms?.existing_property?.zip_code?.zip_code : null,
                city: forms?.existing_property?.city != "" ? forms?.existing_property?.city : null,
                county: forms?.existing_property?.county !== null ? forms?.existing_property?.county?.county_id : null,
                state: forms?.existing_property?.state !== null ? forms?.existing_property?.state?.state_id : null
            }])
        }

        if (forms?.trade_in_application_id === undefined) {
            localStorage.setItem("valuationProviderData", JSON.stringify({}))
        }

    }, [forms])
    
    useEffect(() => {
        if (isComponentMounted && !isSaved) {
            setIsOriginatorChanged(true);
            setOriginatorChangeLoConfirm(true)
            setOriginatorChangeAgentConfirm(true);
        }
        if(formik.values.originatorName !== null){
            let partner = originatorNames.find(p => p.partner_id === Number(formik.values.originatorName));
            if (partner) {
                formik.setFieldValue('calqueFee',partner.calque_percentage_fee);
                formik.setFieldValue('administrative_fee',partner.administrative_fees);
            }
        }
        loanOfficerList.map((list) => {
            if (formik.values.originatorName != list.calque_partner) {
                formik.setFieldValue('loanOfficerFullName', '')
            }
        })
        realEstateAgentList.map((list) => {
            if (formik.values.originatorName != list.calque_partner) {
                formik.setFieldValue('realEstateAgentFullName', '')
            }
        })
        setLoanOfficerState(true);
        setRealEstateAgentState(true);
        setIsSaved(false);
    }, [formik.values.originatorName])
    useEffect(() => {
        if (loanOfficerItems.length > 0) {
            for (let i = 0; i < loanOfficerItems.length; i++) {
                if (formik.values.loanOfficerFullName == loanOfficerItems[i].calque_partner_contact_full_name) {
                    formik.setFieldValue('loanOfficerFirstName', loanOfficerItems[i].first_name)
                    formik.setFieldValue('loanOfficerLastName', loanOfficerItems[i].last_name)
                    formik.setFieldValue('loanOfficerEmail', loanOfficerItems[i].email)
                    formik.setFieldValue('loanOfficerExtension', loanOfficerItems[i].extension)
                    formik.setFieldValue('loanOfficerPhoneNumber', loanOfficerItems[i].phone_number)
                }
            }
        }
    }, [formik.values.loanOfficerFullName,loAddDisable])
    useEffect(() => {
        if (agentItems.length > 0) {
            console.log(agentItems);
            for (let i = 0; i < agentItems.length; i++) {
                if (formik.values.realEstateAgentFullName == agentItems[i].calque_partner_contact_full_name) {
                    formik.setFieldValue('realEstateAgentFullName', formik.values.realEstateAgentFullName)
                    formik.setFieldValue('realEstateAgentFirstName', agentItems[i].first_name)
                    formik.setFieldValue('realEstateAgentLastName', agentItems[i].last_name)
                    formik.setFieldValue('realEstateAgentEmail', agentItems[i].email)
                    formik.setFieldValue('realEstateAgentPhoneNumber', agentItems[i].phone_number)
                    formik.setFieldValue('realEstateAgentExtension', agentItems[i].extension)
                }
            }
        }
    }, [formik.values.realEstateAgentFullName,agentAddDisable])

    useEffect(() => {
        originatorNames && originatorNames.map((org) => {
            if (org?.partner_name == "calque" || org?.partner_name == "Calque") {
                formik.setFieldValue('originatorName', formik.values.originatorName === null ? org.partner_id : formik.values.originatorName)
            }
        })
        if (addPartnerLoanOfficer.length !== 0) {
            formik.setFieldValue('loanOfficerFirstName', addPartnerLoanOfficer?.first_name)
            formik.setFieldValue('loanOfficerLastName', addPartnerLoanOfficer?.last_name)
            formik.setFieldValue('loanOfficerEmail', addPartnerLoanOfficer?.email)
            formik.setFieldValue('loanOfficerPhoneNumber', addPartnerLoanOfficer?.phone_number)
            formik.setFieldValue('loanOfficerExtension', addPartnerLoanOfficer?.extension)
        }
    }, [addPartnerLoanOfficer])
    useEffect(() => {
        originatorNames && originatorNames.map((org) => {
            if (org?.partner_name == "calque" || org?.partner_name == "Calque") {
                formik.setFieldValue('originatorName', formik.values.originatorName === null ? org.partner_id : formik.values.originatorName)
            }
        })
        if (addPartnerRealEstate.length !== 0) {
            formik.setFieldValue('realEstateAgentFirstName', addPartnerRealEstate?.first_name)
            formik.setFieldValue('realEstateAgentLastName', addPartnerRealEstate?.last_name)
            formik.setFieldValue('realEstateAgentEmail', addPartnerRealEstate?.email)
            formik.setFieldValue('realEstateAgentPhoneNumber', addPartnerRealEstate?.phone_number)
            formik.setFieldValue('realEstateAgentExtension', addPartnerRealEstate?.extension)
        }
    }, [addPartnerRealEstate])

    useEffect(() => {
        if (forms?.existing_property?.plunk_avm_value !== '' && forms?.existing_property?.veros_avm_value !== '' && localStorage.getItem('finalVal') != '1' && localStorage.getItem('finalVal') != '2') {
            localStorage.setItem('finalVal', '1,2')
        }
    }, [forms])

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    function HandleLoanOfficerForm(props, orgValue, addBtnDisplay) {
        const [errDisplay, setErrDisplay] = useState(false)
        const [loanOfficerLoader, setLoanOfficerLoader] = useState(false)
        const initialValues = {
            loan_officer_first_name: "",
            loan_officer_last_name: "",
            loan_officer_email: "",
            loan_officer_extension: null,
            loan_officer_phone_number: "",
        }
        const phoneNumberFormatLoanOfficer = (event) => {
            let brackets = event.target.value.replace(/[-()+]/g, '');
            let spaces = brackets.split(" ").join("");
            formik.setFieldValue('loan_officer_phone_number', spaces)
        }
        const handleErrorDisplay = () => {
            setErrDisplay(true)
        }
        useEffect(()=>{
                formik.setFieldValue('loan_officer_first_name', "")
                formik.setFieldValue('loan_officer_last_name', "")
                formik.setFieldValue('loan_officer_email', "")
                formik.setFieldValue('loan_officer_phone_number', "")
                formik.setFieldValue('loan_officer_extension',null)
        },[openSubmitLoanOfficerDetails])
        const validate = values => {
            let errors = {};

            if (values.loan_officer_first_name.trim() === '') {
                errors.loan_officer_first_name = "Please enter first name"
            }
            if (values.loan_officer_last_name.trim() === '') {
                errors.loan_officer_last_name = "Please enter last name"
            }
            if (values.loan_officer_email.trim() === '') {
                errors.loan_officer_email = "Please provide a valid Email Address"
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.loan_officer_email)) {
                errors.loan_officer_email = 'Please provide a valid Email Address';
            }
            return errors;
        }
        const onSubmit = values => {
            let tokenStr = localStorage.getItem('access_token')
            let body = {
                first_name: values.loan_officer_first_name,
                last_name: values.loan_officer_last_name,
                email: values.loan_officer_email.trim(),
                extension: values.loan_officer_extension === null ? null : values.loan_officer_extension,
                phone_number: values.loan_officer_phone_number.replace(/\D/g, ''),
                calque_partner: props?.originatorName?.partner_id ? (orgValue === null ? parseInt(props.originatorName.partner_id) : parseInt(orgValue)) : (orgValue === null ? parseInt(originatorNameDefaultValue) : parseInt(orgValue)),
                partner_contact_role: partnerContactRoleLoan
            }
            setLoanOfficerLoader(true)
            fetch(`${baseUrl.baseUrl}partner-contact/`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${tokenStr}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body),
            })
                .then(async response => {
                    const results = await response.json()
                    if (response.status === 201 || response.status === 200) {
                        setAddPartnerLoanOfficer(results?.partner_contact_details)
                        handleLoanOfficerDetails()
                        addBtnDisplay(true)
                        setErrDisplay(false)
                        setDetail(results.message);
                        setSeverityType("success");
                        setOpenSnackbar(true);
                        setSelectedLo(results?.partner_contact_details);
                        setLoStateChange(false);
                        setLoanOfficerState(false);
                        await sleep(1000);
                        setOpenSnackbar(false);
                        setIsLoAdded(true);
                        setLoanOfficerLoader(false)
                        formik.setFieldValue('loan_officer_first_name', "")
                        formik.setFieldValue('loan_officer_last_name', "")
                        formik.setFieldValue('loan_officer_phone_number', "")
                        formik.setFieldValue('loan_officer_extension', null)
                        formik.setFieldValue('loan_officer_email', "")
                    } else if (response.status === 400) {
                        if (results?.partner_contact_details?.partner_contact_role === 1) {
                            setLoanOfficerErrorMsg(results.message)        
                            setLoanOfficerLoader(false)
                        }
                        setIsLoanOfficerActive(results.partner_contact_details.is_active)
                    } else {
                        if (response.status === 403) {
                            localStorage.setItem('access_token', '')
                            props.history.push('/');
                        }
                        if (results.detail) {
                            setDetail(results.detail)
                            setSeverityType("error");
                            setOpenSnackbar(true);
                            await sleep(1000);
                            setOpenSnackbar(false);
                            setLoanOfficerLoader(false)
                        }
                        else {
                            setDetail("something went wrong")
                            handleLoanOfficerDetails()
                            setSeverityType("error");
                            setOpenSnackbar(true);
                            setErrDisplay(false)
                            formik.setFieldValue('loan_officer_first_name', "")
                            formik.setFieldValue('loan_officer_last_name', "")
                            formik.setFieldValue('loan_officer_phone_number', "")
                            formik.setFieldValue('loan_officer_extension', null)
                            formik.setFieldValue('loan_officer_email', "")
                            await sleep(1000);
                            setOpenSnackbar(false);
                            setLoanOfficerLoader(false)
                        }
                    }
                })
                .catch((error) => {
                    console.log('error', error)
                })
        }
        const formik = useFormik({
            initialValues,
            validate,
            onSubmit,
        });

        return (
            <form onSubmit={formik.handleSubmit} novalidate>
                <div className='loanformDesktop'>
                    <Grid container className="row formDiv section-level-inner">
                        <div style={{ width: '50%' }}>
                            <div className="row formDiv">
                                <label>First Name<span className="red-color">*</span></label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="loan_officer_first_name"
                                    value={formik.values.loan_officer_first_name.trimStart()}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            {
                                errDisplay === true && formik.touched.loan_officer_first_name && formik.errors.loan_officer_first_name ?
                                    <div style={{ padding: '0', margin: '0' }} className="error-msg-form">{formik.errors.loan_officer_first_name}</div>
                                    : ''
                            }
                        </div>
                        <div style={{ width: '50%' }}>
                            <div className="row formDiv">
                                <label>Last Name<span className="red-color">*</span></label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="loan_officer_last_name"
                                    value={formik.values.loan_officer_last_name.trimStart()}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            {
                                errDisplay === true && formik.touched.loan_officer_last_name && formik.errors.loan_officer_last_name ?
                                    <div style={{ padding: '0', margin: '0' }} className="error-msg-form">{formik.errors.loan_officer_last_name}</div>
                                    : ''
                            }
                        </div>
                        <div style={{ width: '50%' }}>
                            <div className="row formDiv">
                                <label>Phone Number</label>
                                <NumberFormat
                                    id="phoneNumber"
                                    className={forms?.trade_in_application_id ? 'MuiInputBase-input MuiOutlinedInput-input ' : 'MuiInputBase-input MuiOutlinedInput-input '}
                                    format="(###) ###-####"
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    allowEmptyFormatting
                                    name="loan_officer_phone_number"
                                    value={formik.values.loan_officer_phone_number}
                                    onChange={(e) => phoneNumberFormatLoanOfficer(e)}
                                    onFocus={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div style={{ width: '50%' }}>
                            <div className="row formDiv">
                                <label>Extension</label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="loan_officer_extension"
                                    value={formik.values.loan_officer_extension}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div style={{ width: '50%' }}>
                            <div className="row formDiv">
                                <label>Email ID<span className="red-color">*</span></label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="loan_officer_email"
                                    value={formik.values.loan_officer_email.trimStart().trimEnd()}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            {
                                errDisplay === true && formik.touched.loan_officer_email && formik.errors.loan_officer_email ?
                                    <div style={{ padding: '0', margin: '0' }} className="error-msg-form">{formik.errors.loan_officer_email}</div>
                                    : ''
                            }
                        </div>
                        <div className="row formDiv"></div>
                    </Grid>
                    {
                        loanofficerErrorMsg !== '' ?
                            <div>
                                <p className='partner-contact-err-msg'>{loanofficerErrorMsg} {isLoanOfficerActive === false ? 'And No Longer Active':''}</p>
                            </div>
                            : null
                    }
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button
                            className="primary_btn"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={handleErrorDisplay}
                            disabled={loanOfficerLoader === true}
                        >
                            {loanOfficerLoader === false ? 'Save' : <CircularProgress className='circular_spiner' size={20} color='white' />}
                        </Button>
                        <NavLink style={{ textDecoration: 'none', color: '#067DE2' }} exact to="/application-create">
                            <Button
                                className="secondary_btn popupCancel"
                                variant="outlined"
                                color="primary"
                                style={{ background: '#fff' }}
                                onClick={handleLoanOfficerDetails}
                            >
                                Cancel
                            </Button>
                        </NavLink>
                    </div>
                </div>
                <div className='loanformMobile'>
                    <Grid container className="row formDiv section-level-inner">
                        <div>
                            <div className="row formDiv">
                                <label>First Name<span className="red-color">*</span></label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="loan_officer_first_name"
                                    value={formik.values.loan_officer_first_name && formik.values.loan_officer_first_name.trimStart()}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            {
                                errDisplay === true && formik.touched.loan_officer_first_name && formik.errors.loan_officer_first_name ?
                                    <div className="row" style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '5%' }}>
                                        <div style={{ padding: '0', margin: '0' }} className="error-msg">{formik.errors.loan_officer_first_name}</div>
                                    </div> : ''
                            }
                        </div>
                        <div>
                            <div className="row formDiv">
                                <label>Last Name<span className="red-color">*</span></label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="loan_officer_last_name"
                                    value={formik.values.loan_officer_last_name && formik.values.loan_officer_last_name.trimStart()}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            {
                                errDisplay === true && formik.touched.loan_officer_last_name && formik.errors.loan_officer_last_name ?
                                    <div className="row" style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '5%' }}>
                                        <div style={{ padding: '0', margin: '0' }} className="error-msg">{formik.errors.loan_officer_last_name}</div>
                                    </div> : ''
                            }
                        </div>
                        <div>
                            <div className="row formDiv">
                                <label>Phone Number</label>
                                <NumberFormat
                                    id="phoneNumber"
                                    className={forms?.trade_in_application_id ? 'MuiInputBase-input MuiOutlinedInput-input ' : 'MuiInputBase-input MuiOutlinedInput-input '}
                                    format="(###) ###-####"
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    allowEmptyFormatting
                                    name="loan_officer_phone_number"
                                    value={formik.values.loan_officer_phone_number}
                                    onChange={(e) => phoneNumberFormatLoanOfficer(e)}
                                    onFocus={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="row formDiv">
                                <label>Extension</label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="loan_officer_extension"
                                    value={formik.values.loan_officer_extension !== null && formik.values.loan_officer_extension.trimStart()}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="row formDiv">
                                <label>Email ID<span className="red-color">*</span></label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="loan_officer_email"
                                    value={formik.values.loan_officer_email && formik.values.loan_officer_email.trimStart().trimEnd()}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            {
                                errDisplay === true && formik.touched.loan_officer_email && formik.errors.loan_officer_email ?
                                    <div className="row" style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '5%' }}>
                                        <div style={{ padding: '0', margin: '0' }} className="error-msg">{formik.errors.loan_officer_email}</div>
                                    </div> : ''
                            }
                        </div>
                        <div className="row formDiv"></div>
                    </Grid>
                    {
                        loanofficerErrorMsg !== '' ?
                            <div>
                                <p className='partner-contact-err-msg'>{loanofficerErrorMsg}</p>
                            </div>
                            : null
                    }
                    <div style={{ display: 'block', alignItems: 'center', justifyContent: 'center' }}>
                        <Button
                            className="primary_btn"
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={loanOfficerLoader === true}
                            onClick={handleErrorDisplay}
                        >
                            {loanOfficerLoader === false ? 'Save' : <CircularProgress className='circular_spiner' size={20} color='white' />}
                        </Button>
                        <NavLink style={{ textDecoration: 'none', color: '#067DE2' }} exact to="/application-create">
                            <Button
                                className="secondary_btn popupCancel"
                                variant="outlined"
                                color="primary"
                                style={{ background: '#fff' }}
                                onClick={handleLoanOfficerDetails}
                            >
                                Cancel
                            </Button>
                        </NavLink>
                    </div>
                </div>
            </form>
        )
    }
    function HandleLoanOfficerEditForm(props, orgValue, validation, setOpenSubmitLoanOfficerEditDetails, openSubmitLOEditDetails, setConfirmLOButtonDisplay, confirmLOButtonDisplay, x, setCheckLoResEdit, setDisplayAddLo, setResponseLOEditAdd) {
        const [loanOfficerLoader, setLoanOfficerLoader] = useState(false)
        const [loanOfficerResponse, setLoanOfficerResponse] = useState(null)

        const initialValues = {
            loan_officer_first_name: "",
            loan_officer_last_name: "",
            loan_officer_email: "",
            loan_officer_extension: null,
            loan_officer_phone_number: "",
        }
        const phoneNumberFormatLoanOfficer = (event) => {
            let brackets = event.target.value.replace(/[-()+]/g, '');
            let spaces = brackets.split(" ").join("");
            formik.setFieldValue('loan_officer_phone_number', spaces)
        }
        const handleErrorDisplay = () => {
            setLoErrDisplay(true);
            setOpenSubmitLoanOfficerEditDetails(openSubmitLOEditDetails)
        }
        const validate = values => {
            let errors = {};

            if (values.loan_officer_first_name?.trim() === '') {
                errors.loan_officer_first_name = "Please enter first name"
            }
            if (values.loan_officer_last_name?.trim() === '') {
                errors.loan_officer_last_name = "Please enter last name"
            }
            if (values.loan_officer_email?.trim() === '') {
                errors.loan_officer_email = "Please provide a valid Email Address"
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.loan_officer_email)) {
                errors.loan_officer_email = 'Please provide a valid Email Address';
            }
            return errors;
        }
        const onSubmit = values => {
            let tokenStr = localStorage.getItem('access_token')
            let body = {
                first_name: values.loan_officer_first_name,
                last_name: values.loan_officer_last_name,
                email: values.loan_officer_email.trim(),
                extension: values.loan_officer_extension === null || values.loan_officer_extension === '' ? null : values.loan_officer_extension,
                phone_number: values?.loan_officer_phone_number?.replace(/\D/g, ''),
                calque_partner: props?.originatorName?.partner_id ? (orgValue === null ? parseInt(props.originatorName.partner_id) : parseInt(orgValue)) : (orgValue === null ? parseInt(originatorNameDefaultValue) : parseInt(orgValue)),
                partner_contact_role: partnerContactRoleLoan,
                trade_in_application_id: props?.trade_in_application_id
            }
            setLoanOfficerLoader(true)
            fetch(`${baseUrl.baseUrl}partner-contact/update/`, {
                method: 'PATCH',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${tokenStr}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body),
            })
                .then(async response => {
                    const results = await response.json()
                    if (response.status === 201 || response.status === 200) {
                        setResponseLOEditAdd(false)
                        setConfirmLOButtonDisplay(true)
                        setDisplayAddLo(false)
                        setLoErrDisplay(false);
                        setLoConfirmResults(null)
                        setNewEntries(results);
                        setDetail(results.message);
                        setSeverityType("success");
                        handleLoanOfficerEditDetails()
                        setLoanOfficerLoader(false)
                        setLoanOfficerResponse(results)
                        setEditedLo(results?.partner_contact_details);
                        setSelectedLo(results?.partner_contact_details);
                        setLoStateChange(false);
                        setLoanOfficerState(false);
                        setCheckLoResEdit(results.message);
                        localStorage.setItem('LoName', results?.partner_contact_details?.calque_partner_contact_id)
                        localStorage.setItem('Lofn', results?.partner_contact_details?.first_name)
                        localStorage.setItem('Loln', results?.partner_contact_details?.last_name)
                        localStorage.setItem('Lopn', results?.partner_contact_details?.phone_number)
                        localStorage.setItem('Loextn', results?.partner_contact_details?.extension)
                        localStorage.setItem('Loemail', results?.partner_contact_details?.email)
                        formik.setFieldValue('loan_officer_first_name', results?.partner_contact_details?.first_name)
                        formik.setFieldValue('loan_officer_last_name', results?.partner_contact_details?.last_name)
                        formik.setFieldValue('loan_officer_phone_number', results?.partner_contact_details?.phone_number)
                        formik.setFieldValue('loan_officer_extension', results?.partner_contact_details?.extension)
                        formik.setFieldValue('loan_officer_email', results?.partner_contact_details?.email)
                        validation.setFieldValue('loanOfficerFirstName', results?.partner_contact_details?.first_name)
                        validation.setFieldValue('loanOfficerLastName', results?.partner_contact_details?.last_name)
                        validation.setFieldValue('loanOfficerPhoneNumber', results?.partner_contact_details?.phone_number)
                        validation.setFieldValue('loanOfficerExtension', results?.partner_contact_details?.extension)
                        validation.setFieldValue('loanOfficerEmail', results?.partner_contact_details?.email)
                        setOpenSnackbar(true);
                        await sleep(2000);
                        setOpenSnackbar(false);
                    } else if (response.status === 400) {
                        if (results?.partner_contact_details?.partner_contact_role === 1) {
                            localStorage.setItem('LoName', results?.partner_contact_details?.calque_partner_contact_id)
                            localStorage.setItem('Lofn', results?.partner_contact_details?.first_name)
                            localStorage.setItem('Loln', results?.partner_contact_details?.last_name)
                            localStorage.setItem('Lopn', results?.partner_contact_details?.phone_number)
                            localStorage.setItem('Loextn', results?.partner_contact_details?.extension)
                            localStorage.setItem('Loemail', results?.partner_contact_details?.email)
                            setResponseLOEditAdd(false)
                            setConfirmLOButtonDisplay(true)
                            setLoanOfficerErrorMsg(results.message)
                            setSeverityType("error");
                            setOpenSnackbar(true);
                            setDetail(results.message);
                            handleLoanOfficerEditDetails()
                            await sleep(2000);
                            setOpenSnackbar(false);
                            setLoanOfficerLoader(false)
                        }
                    } else {
                        if (response.status === 403) {
                            localStorage.setItem('access_token', '')
                            props.history.push('/');
                        }
                        if (results.detail) {
                            setDetail(results.detail)
                            setSeverityType("error");
                            setOpenSnackbar(true);
                            handleLoanOfficerEditDetails()
                            await sleep(1000);
                            setOpenSnackbar(false);
                            setLoanOfficerLoader(false)
                        }
                        else {
                            setDetail("something went wrong")
                            handleLoanOfficerDetails()
                            setSeverityType("error");
                            setOpenSnackbar(true);
                            setLoErrDisplay(false);
                            handleLoanOfficerEditDetails()
                            await sleep(1000);
                            setOpenSnackbar(false);
                            setLoanOfficerLoader(false)
                        }
                    }
                })
                .catch((error) => {
                    console.log('error', error)
                })
        }
        const formik = useFormik({
            initialValues,
            validate,
            onSubmit,
        });
        const handleFirstNameEdit = (eve) => {
            formik.values.loan_officer_first_name = eve
        }
        const handleLastNameEdit = (eve) => {
            formik.values.loan_officer_last_name = eve
        }
        const handleEmailEdit = (eve) => {
            formik.values.loan_officer_email = eve
        }
        const handleExtensionEdit = (eve) => {
            formik.values.loan_officer_extension = eve
        }
        useEffect(() => {
            if (props?.loan_officer === null && props?.loan_officer !== undefined && props?.lo_first_name !== '' && props?.lo_last_name !== '' && loanOfficerResponse === null && localStorage.getItem('LoName') === 'null') {
                formik.setFieldValue('loan_officer_first_name', props?.lo_first_name)
                formik.setFieldValue('loan_officer_last_name', props?.lo_last_name)
                formik.setFieldValue('loan_officer_email', props?.lo_email)
                formik.setFieldValue('loan_officer_phone_number', props?.lo_phone_number)
                formik.setFieldValue('loan_officer_extension', props?.lo_extension)
            } else if(loConfirmResults !== null){
                formik.setFieldValue('loan_officer_first_name', loConfirmResults?.first_name)
                formik.setFieldValue('loan_officer_last_name', loConfirmResults?.last_name)
                formik.setFieldValue('loan_officer_phone_number', loConfirmResults?.phone_number)
                formik.setFieldValue('loan_officer_extension', loConfirmResults?.extension)
                formik.setFieldValue('loan_officer_email', loConfirmResults?.email)
            } else if (loanOfficerResponse === null) {
                formik.values.loan_officer_first_name = props?.loan_officer?.first_name;
                formik.values.loan_officer_last_name = props?.loan_officer?.last_name;
                formik.values.loan_officer_email = props?.loan_officer?.email;
                formik.values.loan_officer_phone_number = props?.loan_officer?.phone_number;
                formik.values.loan_officer_extension = props?.loan_officer?.extension;
            } else if (loanOfficerResponse !== null && props?.loan_officer !== null && props?.loan_officer?.first_name !== loanOfficerResponse?.partner_contact_details?.first_name && props?.loan_officer?.last_name !== loanOfficerResponse?.partner_contact_details?.last_name && props?.loan_officer?.email !== loanOfficerResponse?.partner_contact_details?.email) {
                formik.setFieldValue('loan_officer_first_name', props?.loan_officer?.first_name)
                formik.setFieldValue('loan_officer_last_name', props?.loan_officer?.last_name)
                formik.setFieldValue('loan_officer_phone_number', props?.loan_officer?.phone_number)
                formik.setFieldValue('loan_officer_extension', props?.loan_officer?.extension)
                formik.setFieldValue('loan_officer_email', props?.loan_officer?.email)
            } else if (loanOfficerResponse !== null) {
                formik.values.loan_officer_first_name = loanOfficerResponse?.partner_contact_details?.first_name;
                formik.values.loan_officer_last_name = loanOfficerResponse?.partner_contact_details?.last_name;
                formik.values.loan_officer_email = loanOfficerResponse?.partner_contact_details?.email;
                formik.values.loan_officer_phone_number = loanOfficerResponse?.partner_contact_details?.phone_number;
                formik.values.loan_officer_extension = loanOfficerResponse?.partner_contact_details?.extension;
                formik.setFieldValue('loan_officer_first_name', loanOfficerResponse?.partner_contact_details?.first_name)
                formik.setFieldValue('loan_officer_last_name', loanOfficerResponse?.partner_contact_details?.last_name)
                formik.setFieldValue('loan_officer_phone_number', loanOfficerResponse?.partner_contact_details?.phone_number)
                formik.setFieldValue('loan_officer_extension', loanOfficerResponse?.partner_contact_details?.extension)
                formik.setFieldValue('loan_officer_email', loanOfficerResponse?.partner_contact_details?.email)
            }
        }, [openSubmitLOEditDetails])
        useEffect(() => {
            if (loanOfficerResponse === null) {
                formik.values.loan_officer_first_name = props?.lo_first_name;
                formik.values.loan_officer_last_name = props?.lo_last_name;
                formik.values.loan_officer_email = props?.lo_email;
                formik.values.loan_officer_phone_number = props?.lo_phone_number;
                formik.values.loan_officer_extension = props?.lo_extension;
            } else {
                formik.setFieldValue('loan_officer_first_name', props?.loan_officer?.first_name)
                formik.setFieldValue('loan_officer_last_name', props?.loan_officer?.last_name)
                formik.setFieldValue('loan_officer_phone_number', props?.loan_officer?.phone_number)
                formik.setFieldValue('loan_officer_extension', props?.loan_officer?.extension)
                formik.setFieldValue('loan_officer_email', props?.loan_officer?.email)
            }
        }, [props])

        return (
            <form onSubmit={formik.handleSubmit} novalidate>
                <div className='loanformDesktop'>
                    <Grid container className="row formDiv section-level-inner">
                        <div style={{ width: '50%' }}>
                            <div className="row formDiv">
                                <label>First Name<span className="red-color">*</span></label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="loan_officer_first_name"
                                    defaultValue={props?.loan_officer?.first_name}
                                    value={formik.values.loan_officer_first_name === '' ? null : formik.values.loan_officer_first_name}
                                    onChangeCapture={(e) => { handleFirstNameEdit(e.target.value) }}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            {
                                loErrDisplay === true && formik.touched.loan_officer_first_name && formik.errors.loan_officer_first_name ?
                                    <div style={{ padding: '0', margin: '0' }} className="error-msg-form">{formik.errors.loan_officer_first_name}</div>
                                    : ''
                            }
                        </div>
                        <div style={{ width: '50%' }}>
                            <div className="row formDiv">
                                <label>Last Name<span className="red-color">*</span></label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="loan_officer_last_name"
                                    defaultValue={props?.loan_officer?.last_name}
                                    value={formik.values.loan_officer_last_name === '' ? null : formik.values.loan_officer_last_name}
                                    onChangeCapture={(e) => { handleLastNameEdit(e.target.value) }}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            {
                                loErrDisplay === true && formik.touched.loan_officer_last_name && formik.errors.loan_officer_last_name ?
                                    <div style={{ padding: '0', margin: '0' }} className="error-msg-form">{formik.errors.loan_officer_last_name}</div>
                                    : ''
                            }
                        </div>

                        <div style={{ width: '50%' }}>
                            <div className="row formDiv">
                                <label>Phone Number</label>
                                <NumberFormat
                                    id="phoneNumber"
                                    className={forms?.trade_in_application_id ? 'MuiInputBase-input MuiOutlinedInput-input ' : 'MuiInputBase-input MuiOutlinedInput-input '}
                                    format="(###) ###-####"
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    allowEmptyFormatting
                                    name="loan_officer_phone_number"
                                    defaultValue={props?.loan_officer?.phone_number}
                                    value={(formik.values.loan_officer_phone_number === '' || formik.values.loan_officer_phone_number === null) ? '' : formik.values.loan_officer_phone_number}
                                    onChange={(e) => phoneNumberFormatLoanOfficer(e)}
                                    onFocus={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div style={{ width: '50%' }}>
                            <div className="row formDiv">
                                <label>Extension</label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="loan_officer_extension"
                                    defaultValue={props?.loan_officer?.extension}
                                    value={(formik.values.loan_officer_extension === '' || formik.values.loan_officer_extension === null) ? '' : formik.values.loan_officer_extension}
                                    onChangeCapture={(e) => { handleExtensionEdit(e.target.value) }}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div style={{ width: '50%' }}>
                            <div className="row formDiv">
                                <label>Email ID<span className="red-color">*</span></label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="loan_officer_email"
                                    defaultValue={props?.loan_officer?.email}
                                    value={formik.values.loan_officer_email === '' ? null : formik.values.loan_officer_email}
                                    onChangeCapture={(e) => { handleEmailEdit(e.target.value) }}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            {
                                loErrDisplay === true && formik.touched.loan_officer_email && formik.errors.loan_officer_email ?
                                    <div style={{ padding: '0', margin: '0' }} className="error-msg-form">{formik.errors.loan_officer_email}</div>
                                    : ''
                            }
                        </div>
                        <div className="row formDiv"></div>
                    </Grid>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button
                            className="primary_btn"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={handleErrorDisplay}
                            disabled={loanOfficerLoader === true}
                        >
                            {loanOfficerLoader === false ? 'Save' : <CircularProgress className='circular_spiner' size={20} color='white' />}
                        </Button>
                        <NavLink style={{ textDecoration: 'none', color: '#067DE2' }} exact to="/application-create">
                            <Button
                                className="secondary_btn popupCancel"
                                variant="outlined"
                                color="primary"
                                style={{ background: '#fff' }}
                                onClick={handleLoanOfficerEditDetails}
                            >
                                Cancel
                            </Button>
                        </NavLink>
                    </div>
                </div>
                <div className='loanformMobile'>
                    <Grid container className="row formDiv section-level-inner">
                        <div>
                            <div className="row formDiv">
                                <label>First Name<span className="red-color">*</span></label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="loan_officer_first_name"
                                    defaultValue={props?.loan_officer?.first_name}
                                    value={formik.values.loan_officer_first_name === '' ? null : formik.values.loan_officer_first_name}
                                    onChangeCapture={(e) => { handleFirstNameEdit(e.target.value) }}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            {
                                loErrDisplay === true && formik.touched.loan_officer_first_name && formik.errors.loan_officer_first_name ?
                                    <div className="row" style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '5%' }}>
                                        <div style={{ padding: '0', margin: '0' }} className="error-msg">{formik.errors.loan_officer_first_name}</div>
                                    </div> : ''
                            }
                        </div>
                        <div>
                            <div className="row formDiv">
                                <label>Last Name<span className="red-color">*</span></label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="loan_officer_last_name"
                                    defaultValue={props?.loan_officer?.last_name}
                                    value={formik.values.loan_officer_last_name === '' ? null : formik.values.loan_officer_last_name}
                                    onChangeCapture={(e) => { handleLastNameEdit(e.target.value) }}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            {
                                loErrDisplay === true && formik.touched.loan_officer_last_name && formik.errors.loan_officer_last_name ?
                                    <div className="row" style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '5%' }}>
                                        <div style={{ padding: '0', margin: '0' }} className="error-msg">{formik.errors.loan_officer_last_name}</div>
                                    </div> : ''
                            }
                        </div>
                        <div>
                            <div className="row formDiv">
                                <label>Phone Number</label>
                                <NumberFormat
                                    id="phoneNumber"
                                    className={forms?.trade_in_application_id ? 'MuiInputBase-input MuiOutlinedInput-input ' : 'MuiInputBase-input MuiOutlinedInput-input '}
                                    format="(###) ###-####"
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    allowEmptyFormatting
                                    name="loan_officer_phone_number"
                                    defaultValue={props?.loan_officer?.phone_number}
                                    value={(formik.values.loan_officer_phone_number === '' || formik.values.loan_officer_phone_number === null) ? '' : formik.values.loan_officer_phone_number}
                                    onChange={(e) => phoneNumberFormatLoanOfficer(e)}
                                    onFocus={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="row formDiv">
                                <label>Extension</label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="loan_officer_extension"
                                    defaultValue={props?.loan_officer?.extension}
                                    value={(formik.values.loan_officer_extension === '' || formik.values.loan_officer_extension === null) ? '' : formik.values.loan_officer_extension}
                                    onChangeCapture={(e) => { handleExtensionEdit(e.target.value) }}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="row formDiv">
                                <label>Email ID<span className="red-color">*</span></label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="loan_officer_email"
                                    defaultValue={props?.loan_officer?.email}
                                    value={formik.values.loan_officer_email === '' ? null : formik.values.loan_officer_email}
                                    onChangeCapture={(e) => { handleEmailEdit(e.target.value) }}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            {
                                loErrDisplay === true && formik.touched.loan_officer_email && formik.errors.loan_officer_email ?
                                    <div className="row" style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '5%' }}>
                                        <div style={{ padding: '0', margin: '0' }} className="error-msg">{formik.errors.loan_officer_email}</div>
                                    </div> : ''
                            }
                        </div>
                        <div className="row formDiv"></div>
                    </Grid>
                    <div style={{ display: 'block', alignItems: 'center', justifyContent: 'center' }}>
                        <Button
                            className="primary_btn"
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={loanOfficerLoader === true}
                            onClick={handleErrorDisplay}
                        >
                            {loanOfficerLoader === false ? 'Save' : <CircularProgress className='circular_spiner' size={20} color='white' />}
                        </Button>
                        <NavLink style={{ textDecoration: 'none', color: '#067DE2' }} exact to="/application-create">
                            <Button
                                className="secondary_btn popupCancel"
                                variant="outlined"
                                color="primary"
                                style={{ background: '#fff' }}
                                onClick={handleLoanOfficerEditDetails}
                            >
                                Cancel
                            </Button>
                        </NavLink>
                    </div>
                </div>
            </form>
        )
    }
    function HandleRealEstateAgentEditForm(props, orgValue, validation, setOpenSubmitRealEstateAgentEditDetails, openSubmitRealEstateAgentEditDetails, setConfirmRSAButtonDisplay, confirmRSAButtonDisplay, x, setCheckRsaResEdit, setDisplayAddRSA, setResponseRSAEditAdd) {
        const [realEstateAgentLoader, setRealEstateAgentLoader] = useState(false)
        const [realEstateResponse, setRealEstateResponse] = useState(null)
        const initialValues = {
            real_estate_agent_first_name: "",
            real_estate_agent_last_name: "",
            real_estate_agent_email: "",
            real_estate_agent_extension: null,
            real_estate_agent_phone_number: "",
        }
        const phoneNumberFormatRealEstateForm = (event) => {
            let brackets = event.target.value.replace(/[-()+]/g, '');
            let spaces = brackets.split(" ").join("");
            formik.setFieldValue('real_estate_agent_phone_number', spaces)
        }
        const handleErrorDisplay = () => {
            setRsaErrDisplay(true);
            setOpenSubmitRealEstateAgentEditDetails(openSubmitRealEstateAgentEditDetails)
        }
        const validate = values => {
            let errors = {};

            if (values.real_estate_agent_first_name?.trim() === '') {
                errors.real_estate_agent_first_name = "Please enter first name"
            }
            if (values.real_estate_agent_last_name?.trim() === '') {
                errors.real_estate_agent_last_name = "Please enter last name"
            }

            return errors;
        }
        const onSubmit = values => {
            let tokenStr = localStorage.getItem('access_token')
            let body = {
                first_name: values.real_estate_agent_first_name,
                last_name: values.real_estate_agent_last_name,
                email: values.real_estate_agent_email,
                extension: values.real_estate_agent_extension === null || values.real_estate_agent_extension === '' ? null : values.real_estate_agent_extension,
                phone_number: values?.real_estate_agent_phone_number?.replace(/\D/g, ''),
                calque_partner: props?.originatorName?.partner_id ? (orgValue === null ? parseInt(props.originatorName.partner_id) : parseInt(orgValue)) : (orgValue === null ? parseInt(originatorNameDefaultValue) : parseInt(orgValue)),
                partner_contact_role: partnerContactRoleRealEstate,
                trade_in_application_id: props?.trade_in_application_id
            }
            setRealEstateAgentLoader(true)
            fetch(`${baseUrl.baseUrl}partner-contact/update/`, {
                method: 'PATCH',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${tokenStr}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body),
            })
                .then(async response => {
                    const results = await response.json()
                    if (response.status === 201 || response.status === 200) {
                        setResponseRSAEditAdd(false)
                        setAddPartnerRealestate(results?.partner_contact_details)
                        setDetail(results.message);
                        setConfirmRSAButtonDisplay(true)
                        setAgentConfirmResults(null);
                        setSeverityType("success");
                        setRsaErrDisplay(false);
                        handleRealEstateAgentEditDetails()
                        setRealEstateResponse(results)
                        setCheckRsaResEdit(results.message);
                        setRealEstateAgentLoader(false)
                        setSelectedAgent(results?.partner_contact_details);
                        setRsaStateChange(false);
                        setRealEstateAgentState(false);
                        setEditedAgent(results?.partner_contact_details)
                        localStorage.setItem('RsaName', results?.partner_contact_details?.calque_partner_contact_id)
                        localStorage.setItem('Rsafn', results?.partner_contact_details?.first_name)
                        localStorage.setItem('Rsaln', results?.partner_contact_details?.last_name)
                        localStorage.setItem('Rsapn', results?.partner_contact_details?.phone_number)
                        localStorage.setItem('Rsaextn', results?.partner_contact_details?.extension)
                        localStorage.setItem('Rsaemail', results?.partner_contact_details?.email)
                        formik.setFieldValue('real_estate_agent_first_name', results?.partner_contact_details?.first_name)
                        formik.setFieldValue('real_estate_agent_last_name', results?.partner_contact_details?.last_name)
                        formik.setFieldValue('real_estate_agent_phone_number', results?.partner_contact_details?.phone_number)
                        formik.setFieldValue('real_estate_agent_extension', results?.partner_contact_details?.extension)
                        formik.setFieldValue('real_estate_agent_email', results?.partner_contact_details?.email)

                        validation.setFieldValue('realEstateAgentFullName', results?.partner_contact_details?.calque_partner_contact_id)
                        validation.setFieldValue('realEstateAgentFirstName', results?.partner_contact_details?.first_name)
                        validation.setFieldValue('realEstateAgentLastName', results?.partner_contact_details?.last_name)
                        validation.setFieldValue('realEstateAgentPhoneNumber', results?.partner_contact_details?.phone_number)
                        validation.setFieldValue('realEstateAgentExtension', results?.partner_contact_details?.extension)
                        validation.setFieldValue('realEstateAgentEmail', results?.partner_contact_details?.email)
                        setOpenSnackbar(true);
                        await sleep(1000);
                        setOpenSnackbar(false);
                    } else if (response.status === 400) {
                        if (results?.partner_contact_details?.partner_contact_role === 2) {
                            validation.setFieldValue('realEstateAgentFullName', results?.partner_contact_details?.calque_partner_contact_id)
                            localStorage.setItem('Rsafn', results?.partner_contact_details?.first_name)
                            localStorage.setItem('Rsaln', results?.partner_contact_details?.last_name)
                            localStorage.setItem('Rsapn', results?.partner_contact_details?.phone_number)
                            localStorage.setItem('Rsaextn', results?.partner_contact_details?.extension)
                            localStorage.setItem('Rsaemail', results?.partner_contact_details?.email)
                            localStorage.setItem('RsaName', results?.partner_contact_details?.calque_partner_contact_id)
                            setResponseRSAEditAdd(false)
                            setConfirmRSAButtonDisplay(true)
                            setRealEstateAgentErrorMsg(results.message)
                            setDetail(results.message);
                            setSeverityType("error");
                            setOpenSnackbar(true);
                            handleRealEstateAgentEditDetails()
                            await sleep(2000);
                            setOpenSnackbar(false);
                            setRealEstateAgentLoader(false)
                        }
                    }
                    else {
                        if (response.status === 403) {
                            localStorage.setItem('access_token', '')
                            props.history.push('/');
                        }
                        if (results.detail) {
                            setDetail(results.detail)
                            setSeverityType("error");
                            setOpenSnackbar(true);
                            await sleep(1000);
                            setOpenSnackbar(false);
                            setRealEstateAgentLoader(false)
                        }
                        else {
                            setDetail("something went wrong")
                            setSeverityType("error");
                            setOpenSnackbar(true);
                            setRsaErrDisplay(false);
                            handleRealEstateAgentEditDetails()
                            setRealEstateAgentLoader(false)
                            await sleep(1000);
                            setOpenSnackbar(false);
                        }
                    }
                })
                .catch((error) => {
                    console.log('error', error)
                })
        }
        const formik = useFormik({
            initialValues,
            validate,
            onSubmit,
        });
        const handleFirstNameEdit = (eve) => {
            formik.values.real_estate_agent_first_name = eve
        }
        const handleLastNameEdit = (eve) => {
            formik.values.loan_officer_last_name = eve
        }
        const handleEmailEdit = (eve) => {
            formik.values.real_estate_agent_email = eve
        }
        const handleExtensionEdit = (eve) => {
            formik.values.real_estate_agent_extension = eve
        }
        useEffect(() => {
            if (props?.real_estate_agent === null && props?.real_estate_agent !== undefined && props?.agent_first_name !== '' && props?.agent_last_name !== '' && realEstateResponse === null && localStorage.getItem('RsaName') === 'null') {
                formik.setFieldValue('real_estate_agent_first_name', props?.agent_first_name)
                formik.setFieldValue('real_estate_agent_last_name', props?.agent_last_name)
                formik.setFieldValue('real_estate_agent_email', props?.agent_email)
                formik.setFieldValue('real_estate_agent_phone_number', props?.agent_phone_number)
                formik.setFieldValue('real_estate_agent_extension', props?.agent_extension)
            }else if(agentConfirmResults !== null){
                formik.setFieldValue('real_estate_agent_first_name', agentConfirmResults?.first_name)
                formik.setFieldValue('real_estate_agent_last_name', agentConfirmResults?.last_name)
                formik.setFieldValue('real_estate_agent_phone_number', agentConfirmResults?.phone_number)
                formik.setFieldValue('real_estate_agent_extension', agentConfirmResults?.extension)
                formik.setFieldValue('real_estate_agent_email', agentConfirmResults?.email)
            } else if (realEstateResponse === null) {
                formik.values.real_estate_agent_first_name = props?.real_estate_agent?.first_name;
                formik.values.real_estate_agent_last_name = props?.real_estate_agent?.last_name;
                formik.values.real_estate_agent_email = props?.real_estate_agent?.email;
                formik.values.real_estate_agent_phone_number = props?.real_estate_agent?.phone_number;
                formik.values.real_estate_agent_extension = props?.real_estate_agent?.extension;
            } else if (realEstateResponse !== null && props?.real_estate_agent !== null && props?.real_estate_agent?.first_name !== realEstateResponse?.partner_contact_details?.first_name && props?.real_estate_agent?.last_name !== realEstateResponse?.partner_contact_details?.last_name && props?.real_estate_agent?.email !== realEstateResponse?.partner_contact_details?.email) {
                formik.setFieldValue('real_estate_agent_first_name', props?.real_estate_agent?.first_name)
                formik.setFieldValue('real_estate_agent_last_name', props?.real_estate_agent?.last_name)
                formik.setFieldValue('real_estate_agent_phone_number', props?.real_estate_agent?.phone_number)
                formik.setFieldValue('real_estate_agent_extension', props?.real_estate_agent?.extension)
                formik.setFieldValue('real_estate_agent_email', props?.real_estate_agent?.email)
            } else if (realEstateResponse !== null) {
                formik.values.real_estate_agent_first_name = realEstateResponse?.partner_contact_details?.first_name;
                formik.values.real_estate_agent_last_name = realEstateResponse?.partner_contact_details?.last_name;
                formik.values.real_estate_agent_email = realEstateResponse?.partner_contact_details?.email;
                formik.values.real_estate_agent_phone_number = realEstateResponse?.partner_contact_details?.phone_number;
                formik.values.real_estate_agent_extension = realEstateResponse?.partner_contact_details?.extension;
                formik.setFieldValue('real_estate_agent_first_name', realEstateResponse?.partner_contact_details?.first_name)
                formik.setFieldValue('real_estate_agent_last_name', realEstateResponse?.partner_contact_details?.last_name)
                formik.setFieldValue('real_estate_agent_email', realEstateResponse?.partner_contact_details?.email)
                formik.setFieldValue('real_estate_agent_phone_number', realEstateResponse?.partner_contact_details?.phone_number)
                formik.setFieldValue('real_estate_agent_extension', realEstateResponse?.partner_contact_details?.extension)
            }
        }, [openSubmitRealEstateAgentEditDetails])
        useEffect(() => {
            if (realEstateResponse === null) {
                formik.values.real_estate_agent_first_name = props?.agent_first_name;
                formik.values.real_estate_agent_last_name = props?.agent_last_name;
                formik.values.real_estate_agent_email = props?.agent_email;
                formik.values.real_estate_agent_phone_number = props?.agent_phone_number;
                formik.values.real_estate_agent_extension = props?.agent_extension;
            } else {
                formik.setFieldValue('real_estate_agent_first_name', props?.real_estate_agent?.first_name)
                formik.setFieldValue('real_estate_agent_last_name', props?.real_estate_agent?.last_name)
                formik.setFieldValue('real_estate_agent_email', props?.real_estate_agent?.email)
                formik.setFieldValue('real_estate_agent_phone_number', props?.real_estate_agent?.phone_number)
                formik.setFieldValue('real_estate_agent_extension', props?.real_estate_agent?.extension)
            }
        }, [props])

        return (
            <form onSubmit={formik.handleSubmit} novalidate>
                <div className='realestateformDesktop'>
                    <Grid container className="row formDiv section-level-inner">
                        <div style={{ width: '50%' }}>
                            <div className="row formDiv">
                                <label>First Name<span className="red-color">*</span></label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="real_estate_agent_first_name"
                                    onChange={formik.handleChange}
                                    defaultValue={props?.real_estate_agent?.first_name}
                                    value={formik.values.real_estate_agent_first_name === '' ? null : formik.values.real_estate_agent_first_name}
                                    onChangeCapture={(e) => { handleFirstNameEdit(e.target.value) }}
                                />
                            </div>
                            {
                                rsaErrDisplay === true && formik.touched.real_estate_agent_first_name && formik.errors.real_estate_agent_first_name ?
                                    <div style={{ padding: '0', margin: '0' }} className="error-msg-form">{formik.errors.real_estate_agent_first_name}</div>
                                    : ''
                            }
                        </div>
                        <div style={{ width: '50%' }}>
                            <div className="row formDiv">
                                <label>Last Name<span className="red-color">*</span></label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="real_estate_agent_last_name"
                                    onChange={formik.handleChange}
                                    defaultValue={props?.real_estate_agent?.last_name}
                                    value={formik.values.real_estate_agent_last_name === '' ? null : formik.values.real_estate_agent_last_name}
                                    onChangeCapture={(e) => { handleLastNameEdit(e.target.value) }}
                                />
                            </div>
                            {
                                rsaErrDisplay === true && formik.touched.real_estate_agent_last_name && formik.errors.real_estate_agent_last_name ?
                                    <div style={{ padding: '0', margin: '0' }} className="error-msg-form">{formik.errors.real_estate_agent_last_name}</div>
                                    : ''
                            }
                        </div>

                        <div style={{ width: '50%' }}>
                            <div className="row formDiv">
                                <label>Phone Number</label>
                                <NumberFormat
                                    id="phoneNumber"
                                    className={forms?.trade_in_application_id ? 'MuiInputBase-input MuiOutlinedInput-input ' : 'MuiInputBase-input MuiOutlinedInput-input '}
                                    format="(###) ###-####"
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    allowEmptyFormatting
                                    name="real_estate_agent_phone_number"
                                    defaultValue={props?.real_estate_agent?.phone_number}
                                    value={formik.values.real_estate_agent_phone_number === '' || formik.values.real_estate_agent_phone_number === null ? '' : formik.values.real_estate_agent_phone_number}
                                    onChange={(e) => phoneNumberFormatRealEstateForm(e)}
                                    onFocus={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div style={{ width: '50%' }}>
                            <div className="row formDiv">
                                <label>Extension</label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    onKeyDown={handleKeyDown}
                                    name="real_estate_agent_extension"
                                    onChange={formik.handleChange}
                                    defaultValue={props?.real_estate_agent?.extension}
                                    value={formik.values.real_estate_agent_extension === '' || formik.values.real_estate_agent_extension === null ? '' : formik.values.real_estate_agent_extension}
                                    onChangeCapture={(e) => { handleExtensionEdit(e.target.value) }}
                                />
                            </div>
                        </div>
                        <div style={{ width: '50%' }}>
                            <div className="row formDiv">
                                <label>Email ID</label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="real_estate_agent_email"
                                    onChange={formik.handleChange}
                                    defaultValue={props?.real_estate_agent?.email}
                                    value={formik.values.real_estate_agent_email === '' || formik.values.real_estate_agent_email === null ? '' : formik.values.real_estate_agent_email}
                                    onChangeCapture={(e) => { handleEmailEdit(e.target.value) }}
                                />
                            </div>
                            {
                                rsaErrDisplay === true && formik.touched.real_estate_agent_email && formik.errors.real_estate_agent_email ?
                                    <div style={{ padding: '0', margin: '0' }} className="error-msg-form">{formik.errors.real_estate_agent_email}</div>
                                    : ''
                            }
                        </div>
                        <div className="row formDiv"></div>
                    </Grid>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button
                            className="primary_btn"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={handleErrorDisplay}
                            disabled={realEstateAgentLoader === true}
                        >
                            {realEstateAgentLoader === false ? 'Save' : <CircularProgress className='circular_spiner' size={20} color='white' />}
                        </Button>
                        <NavLink style={{ textDecoration: 'none', color: '#067DE2' }} exact to="/application-create">
                            <Button
                                className="secondary_btn popupCancel"
                                variant="outlined"
                                color="primary"
                                style={{ background: '#fff' }}
                                onClick={handleRealEstateAgentEditDetails}
                            >
                                Cancel
                            </Button>
                        </NavLink>
                    </div>
                </div>
                <div className='realestateformMobile'>
                    <Grid container className="row formDiv section-level-inner">
                        <div>
                            <div className="row formDiv">
                                <label>First Name<span className="red-color">*</span></label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="real_estate_agent_first_name"
                                    onChange={formik.handleChange}
                                    defaultValue={props?.real_estate_agent?.first_name}
                                    value={formik.values.real_estate_agent_first_name === '' ? null : formik.values.real_estate_agent_first_name}
                                    onChangeCapture={(e) => { handleFirstNameEdit(e.target.value) }}
                                />
                            </div>
                            {
                                rsaErrDisplay === true && formik.touched.real_estate_agent_first_name && formik.errors.real_estate_agent_first_name ?
                                    <div className="row" style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '5%' }}>
                                        <div style={{ padding: '0', margin: '0' }} className="error-msg">{formik.errors.real_estate_agent_first_name}</div>
                                    </div> : ''
                            }
                        </div>
                        <div>
                            <div className="row formDiv">
                                <label>Last Name<span className="red-color">*</span></label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="real_estate_agent_last_name"
                                    onChange={formik.handleChange}
                                    defaultValue={props?.real_estate_agent?.last_name}
                                    value={formik.values.real_estate_agent_last_name === '' ? null : formik.values.real_estate_agent_last_name}
                                    onChangeCapture={(e) => { handleLastNameEdit(e.target.value) }}
                                />
                            </div>
                            {
                                rsaErrDisplay === true && formik.touched.real_estate_agent_last_name && formik.errors.real_estate_agent_last_name ?
                                    <div className="row" style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '5%' }}>
                                        <div style={{ padding: '0', margin: '0' }} className="error-msg">{formik.errors.real_estate_agent_last_name}</div>
                                    </div> : ''
                            }
                        </div>

                        <div>
                            <div className="row formDiv">
                                <label>Phone Number</label>
                                <NumberFormat
                                    id="phoneNumber"
                                    className={forms?.trade_in_application_id ? 'MuiInputBase-input MuiOutlinedInput-input ' : 'MuiInputBase-input MuiOutlinedInput-input '}
                                    format="(###) ###-####"
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    allowEmptyFormatting
                                    name="real_estate_agent_phone_number"
                                    defaultValue={props?.real_estate_agent?.phone_number}
                                    value={formik.values.real_estate_agent_phone_number === '' || formik.values.real_estate_agent_phone_number === null ? '' : formik.values.real_estate_agent_phone_number}
                                    onChange={(e) => phoneNumberFormatRealEstateForm(e)}
                                    onFocus={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="row formDiv">
                                <label>Extension</label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="real_estate_agent_extension"
                                    onChange={formik.handleChange}
                                    defaultValue={props?.real_estate_agent?.extension}
                                    value={formik.values.real_estate_agent_extension === '' || formik.values.real_estate_agent_extension === null ? '' : formik.values.real_estate_agent_extension}
                                    onChangeCapture={(e) => { handleExtensionEdit(e.target.value) }}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="row formDiv">
                                <label>Email ID<span className="red-color">*</span></label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="real_estate_agent_email"
                                    onChange={formik.handleChange}
                                    defaultValue={props?.real_estate_agent?.email}
                                    value={formik.values.real_estate_agent_email === '' || formik.values.real_estate_agent_email === null ? '' : formik.values.real_estate_agent_email}
                                    onChangeCapture={(e) => { handleEmailEdit(e.target.value) }}
                                />
                            </div>
                            {
                                rsaErrDisplay === true && formik.touched.real_estate_agent_email && formik.errors.real_estate_agent_email ?
                                    <div className="row" style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '5%' }}>
                                        <div style={{ padding: '0', margin: '0' }} className="error-msg">{formik.errors.real_estate_agent_email}</div>
                                    </div> : ''
                            }
                        </div>
                        <div className="row formDiv"></div>
                    </Grid>
                    <div style={{ display: 'block', alignItems: 'center', justifyContent: 'center' }}>
                        <Button
                            className="primary_btn"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={handleErrorDisplay}
                            disabled={realEstateAgentLoader === true}
                        >
                            {realEstateAgentLoader === false ? 'Save' : <CircularProgress className='circular_spiner' size={20} color='white' />}
                        </Button>
                        <NavLink style={{ textDecoration: 'none', color: '#067DE2' }} exact to="/application-create">
                            <Button
                                className="secondary_btn popupCancel"
                                variant="outlined"
                                color="primary"
                                style={{ background: '#fff' }}
                                onClick={handleRealEstateAgentEditDetails}
                            >
                                Cancel
                            </Button>
                        </NavLink>
                    </div>
                </div>
            </form>
        )
    }
    function HandleRealEstateAgentForm(props, orgValue, addBtnDisplayRSA) {
        const [errDisplay, setErrDisplay] = useState(false)
        const [realEstateAgentLoader, setRealEstateAgentLoader] = useState(false)
        const initialValues = {
            real_estate_agent_first_name: "",
            real_estate_agent_last_name: "",
            real_estate_agent_email: "",
            real_estate_agent_extension: null,
            real_estate_agent_phone_number: "",
        }
        const phoneNumberFormatRealEstateForm = (event) => {
            let brackets = event.target.value.replace(/[-()+]/g, '');
            let spaces = brackets.split(" ").join("");
            formik.setFieldValue('real_estate_agent_phone_number', spaces)
        }
        const handleErrorDisplay = () => {
            setErrDisplay(true)
        }
        useEffect(()=>{
                formik.setFieldValue('real_estate_agent_first_name', "")
                formik.setFieldValue('real_estate_agent_last_name', "")
                formik.setFieldValue('real_estate_agent_email', "")
                formik.setFieldValue('real_estate_agent_phone_number', "")
                formik.setFieldValue('real_estate_agent_extension', null)
        },[openSubmitRealEstateAgentDetails])
        const validate = values => {
            let errors = {};

            if (values.real_estate_agent_first_name.trim() === '') {
                errors.real_estate_agent_first_name = "Please enter first name"
            }
            if (values.real_estate_agent_last_name.trim() === '') {
                errors.real_estate_agent_last_name = "Please enter last name"
            }

            return errors;
        }
        const onSubmit = values => {
            let tokenStr = localStorage.getItem('access_token')
            let body = {
                first_name: values.real_estate_agent_first_name,
                last_name: values.real_estate_agent_last_name,
                email: values.real_estate_agent_email,
                extension: values.real_estate_agent_extension === null ? null : values.real_estate_agent_extension,
                phone_number: values.real_estate_agent_phone_number.replace(/\D/g, ''),
                calque_partner: props?.originatorName?.partner_id ? (orgValue === null ? parseInt(props.originatorName.partner_id) : parseInt(orgValue)) : (orgValue === null ? parseInt(originatorNameDefaultValue) : parseInt(orgValue)),
                partner_contact_role: partnerContactRoleRealEstate
            }
            setRealEstateAgentLoader(true)
            fetch(`${baseUrl.baseUrl}partner-contact/`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${tokenStr}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body),
            })
                .then(async response => {
                    const results = await response.json()
                    if (response.status === 201 || response.status === 200) {
                        setAddPartnerRealestate(results?.partner_contact_details)
                        setDetail(results.message);
                        addBtnDisplayRSA(true)
                        setSeverityType("success");
                        setOpenSnackbar(true);
                        setErrDisplay(false)
                        handleRealEstateAgentDetails()
                        await sleep(1000);
                        setOpenSnackbar(false);
                        setIsAgentAdded(true);
                        setSelectedAgent(results?.partner_contact_details);
                        setRsaStateChange(false);
                        setRealEstateAgentState(false);
                        setRealEstateAgentLoader(false)
                        formik.setFieldValue('real_estate_agent_first_name', "")
                        formik.setFieldValue('real_estate_agent_last_name', "")
                        formik.setFieldValue('real_estate_agent_phone_number', "")
                        formik.setFieldValue('real_estate_agent_extension', null)
                        formik.setFieldValue('real_estate_agent_email', "")
                    } else if (response.status === 400) {
                        if (results?.partner_contact_details?.partner_contact_role === 2) {
                            setRealEstateAgentErrorMsg(results.message)
                            setRealEstateAgentLoader(false)
                        }
                        setIsRealEstateAgentActive(results.partner_contact_details.is_active)
                    }
                    else {
                        if (response.status === 403) {
                            localStorage.setItem('access_token', '')
                            props.history.push('/');
                        }
                        if (results.detail) {
                            setDetail(results.detail)
                            setSeverityType("error");
                            setOpenSnackbar(true);
                            await sleep(1000);
                            setOpenSnackbar(false);
                            setRealEstateAgentLoader(false)
                        }
                        else {
                            setDetail("something went wrong")
                            setSeverityType("error");
                            setOpenSnackbar(true);
                            setErrDisplay(false)
                            handleRealEstateAgentDetails()
                            setRealEstateAgentLoader(false)
                            formik.setFieldValue('real_estate_agent_first_name', "")
                            formik.setFieldValue('real_estate_agent_last_name', "")
                            formik.setFieldValue('real_estate_agent_phone_number', "")
                            formik.setFieldValue('real_estate_agent_extension', null)
                            formik.setFieldValue('real_estate_agent_email', "")
                            await sleep(1000);
                            setOpenSnackbar(false);
                        }
                    }
                })
                .catch((error) => {
                    console.log('error', error)
                })
        }
        const formik = useFormik({
            initialValues,
            validate,
            onSubmit,
        });
        return (
            <form onSubmit={formik.handleSubmit} novalidate>
                <div className='realestateformDesktop'>
                    <Grid container className="row formDiv section-level-inner">
                        <div style={{ width: '50%' }}>
                            <div className="row formDiv">
                                <label>First Name<span className="red-color">*</span></label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="real_estate_agent_first_name"
                                    value={formik.values.real_estate_agent_first_name && formik.values.real_estate_agent_first_name.trimStart()}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            {
                                errDisplay === true && formik.touched.real_estate_agent_first_name && formik.errors.real_estate_agent_first_name ?
                                    <div style={{ padding: '0', margin: '0' }} className="error-msg-form">{formik.errors.real_estate_agent_first_name}</div>
                                    : ''
                            }
                        </div>
                        <div style={{ width: '50%' }}>
                            <div className="row formDiv">
                                <label>Last Name<span className="red-color">*</span></label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="real_estate_agent_last_name"
                                    value={formik.values.real_estate_agent_last_name && formik.values.real_estate_agent_last_name.trimStart()}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            {
                                errDisplay === true && formik.touched.real_estate_agent_last_name && formik.errors.real_estate_agent_last_name ?
                                    <div style={{ padding: '0', margin: '0' }} className="error-msg-form">{formik.errors.real_estate_agent_last_name}</div>
                                    : ''
                            }
                        </div>

                        <div style={{ width: '50%' }}>
                            <div className="row formDiv">
                                <label>Phone Number</label>
                                <NumberFormat
                                    id="phoneNumber"
                                    className={forms?.trade_in_application_id ? 'MuiInputBase-input MuiOutlinedInput-input ' : 'MuiInputBase-input MuiOutlinedInput-input '}
                                    format="(###) ###-####"
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    allowEmptyFormatting
                                    name="real_estate_agent_phone_number"
                                    value={formik.values.real_estate_agent_phone_number}
                                    onChange={(e) => phoneNumberFormatRealEstateForm(e)}
                                    onFocus={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div style={{ width: '50%' }}>
                            <div className="row formDiv">
                                <label>Extension</label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    onKeyDown={handleKeyDown}
                                    name="real_estate_agent_extension"
                                    value={formik.values.real_estate_agent_extension}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div style={{ width: '50%' }}>
                            <div className="row formDiv">
                                <label>Email ID</label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="real_estate_agent_email"
                                    value={formik.values.real_estate_agent_email && formik.values.real_estate_agent_email.trimStart().trimEnd()}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            {
                                errDisplay === true && formik.touched.real_estate_agent_email && formik.errors.real_estate_agent_email ?
                                    <div style={{ padding: '0', margin: '0' }} className="error-msg-form">{formik.errors.real_estate_agent_email}</div>
                                    : ''
                            }
                        </div>
                        <div className="row formDiv"></div>
                    </Grid>
                    {
                        realEstateAgentErrorMsg !== '' ?
                            <div>
                                <p className='partner-contact-err-msg'>{realEstateAgentErrorMsg} {isRealEstateAgentActive === false?'And No Longer Active':''}</p>
                            </div>
                            : null
                    }
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button
                            className="primary_btn"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={handleErrorDisplay}
                            disabled={realEstateAgentLoader === true}
                        >
                            {realEstateAgentLoader === false ? 'Save' : <CircularProgress className='circular_spiner' size={20} color='white' />}
                        </Button>
                        <NavLink style={{ textDecoration: 'none', color: '#067DE2' }} exact to="/application-create">
                            <Button
                                className="secondary_btn popupCancel"
                                variant="outlined"
                                color="primary"
                                style={{ background: '#fff' }}
                                onClick={handleRealEstateAgentDetails}
                            >
                                Cancel
                            </Button>
                        </NavLink>
                    </div>
                </div>
                <div className='realestateformMobile'>
                    <Grid container className="row formDiv section-level-inner">
                        <div>
                            <div className="row formDiv">
                                <label>First Name<span className="red-color">*</span></label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="real_estate_agent_first_name"
                                    value={formik.values.real_estate_agent_first_name}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            {
                                errDisplay === true && formik.touched.real_estate_agent_first_name && formik.errors.real_estate_agent_first_name ?
                                    <div className="row" style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '5%' }}>
                                        <div style={{ padding: '0', margin: '0' }} className="error-msg">{formik.errors.real_estate_agent_first_name}</div>
                                    </div> : ''
                            }
                        </div>
                        <div>
                            <div className="row formDiv">
                                <label>Last Name<span className="red-color">*</span></label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="real_estate_agent_last_name"
                                    value={formik.values.real_estate_agent_last_name}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            {
                                errDisplay === true && formik.touched.real_estate_agent_last_name && formik.errors.real_estate_agent_last_name ?
                                    <div className="row" style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '5%' }}>
                                        <div style={{ padding: '0', margin: '0' }} className="error-msg">{formik.errors.real_estate_agent_last_name}</div>
                                    </div> : ''
                            }
                        </div>

                        <div>
                            <div className="row formDiv">
                                <label>Phone Number</label>
                                <NumberFormat
                                    id="phoneNumber"
                                    className={forms?.trade_in_application_id ? 'MuiInputBase-input MuiOutlinedInput-input ' : 'MuiInputBase-input MuiOutlinedInput-input '}
                                    format="(###) ###-####"
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    allowEmptyFormatting
                                    name="real_estate_agent_phone_number"
                                    value={formik.values.real_estate_agent_phone_number}
                                    onChange={(e) => phoneNumberFormatRealEstateForm(e)}
                                    onFocus={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="row formDiv">
                                <label>Extension</label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="real_estate_agent_extension"
                                    value={formik.values.real_estate_agent_extension}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="row formDiv">
                                <label>Email ID<span className="red-color">*</span></label>
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name="real_estate_agent_email"
                                    value={formik.values.real_estate_agent_email}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            {
                                errDisplay === true && formik.touched.real_estate_agent_email && formik.errors.real_estate_agent_email ?
                                    <div className="row" style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '5%' }}>
                                        <div style={{ padding: '0', margin: '0' }} className="error-msg">{formik.errors.real_estate_agent_email}</div>
                                    </div> : ''
                            }
                        </div>
                        <div className="row formDiv"></div>
                    </Grid>
                    {
                        realEstateAgentErrorMsg !== '' ?
                            <div>
                                <p className='partner-contact-err-msg'>{realEstateAgentErrorMsg}</p>
                            </div>
                            : null
                    }
                    <div style={{ display: 'block', alignItems: 'center', justifyContent: 'center' }}>
                        <Button
                            className="primary_btn"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={handleErrorDisplay}
                            disabled={realEstateAgentLoader === true}
                        >
                            {realEstateAgentLoader === false ? 'Save' : <CircularProgress className='circular_spiner' size={20} color='white' />}
                        </Button>
                        <NavLink style={{ textDecoration: 'none', color: '#067DE2' }} exact to="/application-create">
                            <Button
                                className="secondary_btn popupCancel"
                                variant="outlined"
                                color="primary"
                                style={{ background: '#fff' }}
                                onClick={handleRealEstateAgentDetails}
                            >
                                Cancel
                            </Button>
                        </NavLink>
                    </div>
                </div>
            </form>
        )
    }
   
    return (
        <>
            <div className="tab-content">
                <section>
                    {loaderStatus === true ?
                        <PageLoader />
                        : (
                            <>
                            <CommonHeader cxSpecialists={props.cxSpecialists} statusOffer={props.statusOffer} cxSpecialist={props.cxSpecialist} setCxSpecialist={props.setCxSpecialist} />
                            <form onSubmit={formik.handleSubmit} novalidate>
                                <h3 className="section-title">PPG Application</h3>
                                <Grid container className="section-level">
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Created Date</label>
                                        <span>{formik.values.createDate}</span>
                                    </Grid>
                                    <Grid container xs={12} sm={12} md={6} lg={6} className="row formDiv radio-btn-div">
                                        <label className="radio-label">Test Application</label>
                                        <div className="d-flex justify-start align-center">
                                            <RadioGroup
                                                aria-label="test application"
                                                name="is_test_app"
                                                className="d-flex justify-start align-center row-direction"
                                                value={isTest !== null ? isTest : testApp}
                                                onChange={(e) => handleTestApp(e.target.value)}
                                            >
                                                <div className="d-flex justify-start align-center row-direction">
                                                    <FormControlLabel disableripple className="mr-10 w-70" disabled="true" value={true} control={<Radio disableripple color='primary' />} label='Yes' labelPlacement="end" />
                                                    <FormControlLabel disableripple className="mr-10 w-70" disabled="true" value={false} control={<Radio disableripple color='primary' />} label='No' labelPlacement="end" />
                                                </div>
                                            </RadioGroup>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Created By</label>
                                        <span>{formik.values.createdBy}</span>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv radio-btn-div">
                                        <label className="radio-label">Submitted to CRM</label>
                                        <div className="d-flex justify-start align-center">
                                            <RadioGroup
                                                aria-label="submitted to crm"
                                                name="submitted_to_crm"
                                                className="d-flex justify-start align-center row-direction"
                                                value={submittedCRM}
                                                onChange={(e) => handleSubmittedCRM(e.target.value)}
                                            >
                                                <div className="d-flex justify-start align-center row-direction">
                                                    <FormControlLabel disableripple className="mr-10 w-70" disabled="true" value={true} control={<Radio disableripple color='primary' />} label='Yes' labelPlacement="end" />
                                                    <FormControlLabel disableripple className="mr-10 w-70" disabled="true" value={false} control={<Radio disableripple color='primary' />} label='No' labelPlacement="end" />
                                                </div>
                                            </RadioGroup>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Updated Date</label>
                                        <span>{formik.values.updatedDate}</span>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Application ID</label>
                                        <span>{formik.values.applicationID}</span>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Updated By</label>
                                        <span>{formik.values.updatedBy}</span>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>External Reference No</label>
                                        <TextField
                                            type="text"
                                            variant="outlined"
                                            name="externalReference"
                                            onChange={formik.handleChange}
                                            value={formik.values.externalReference}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Source</label>
                                        <span>{forms?.application_source?.partner_name}</span>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Originator Name<span className="red-color">*</span></label>
                                        <div class="views-widget" style={{marginLeft:"4px"}}>
                                            <select
                                                id="originator"
                                                name='originatorName'
                                                className="form_select form-select-mgp"
                                                style={{ color: '#000' }}
                                                onChange={formik.handleChange}
                                                value={formik.values.originatorName}
                                            >
                                                <option value="">---Select Originator Name---</option>
                                                {originatorNames && originatorNames.map((item, index) => (
                                                    <option key={index} value={item.partner_id} >
                                                        {item.partner_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </Grid>
                                    <Grid container>
                                    <Grid xs={12} sm={12} md={6} lg={6} className="row formDiv radio-btn-div">
                                        <label className="radio-label">Loan Officer</label>
                                        <div className="d-flex justify-start align-center">
                                            <RadioGroup
                                                aria-label="loan officer"
                                                name="is_loan_officer"
                                                className="d-flex justify-start align-center row-direction"
                                                value={loanOfficer}
                                                onChange={(e) => handleLoanOfficer(e.target.value)}
                                            >
                                                <div className="d-flex justify-start align-center row-direction">
                                                    <FormControlLabel disableripple className="mr-10 w-70" value="yes" control={<Radio disableripple color='primary' />} label="Yes" labelPlacement="end" />
                                                    <FormControlLabel disableripple className="mr-10 w-70" value="no" control={<Radio disableripple color='primary' />} label="No" labelPlacement="end" />
                                                </div>
                                            </RadioGroup>
                                        </div>
                                    </Grid>
                                    <Grid container className="row formDiv section-level-inner" style={loanOfficer === 'yes' ? show : hide}>
                                        {handleLoanOfficerMsg()}
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Loan Officer Name<span className="red-color">*</span></label>
                                            <Autocomplete
                                                ref={ref}
                                                name='loanOfficerFullName'
                                                popupIcon={false}
                                                onChangeCapture={handleSearchValue}
                                                value={formik.values.loanOfficerFullName}
                                                onChange={(event,newValue) => { handleChangeLOValue(newValue) }}
                                                open={isOpen}
                                                options={loanOfficerItems && loanOfficerItems.map((lo) => lo.calque_partner_contact_full_name)}
                                                getOptionLabel={(option) => (typeof option === 'string' ? option : "")}
                                                noOptionsText="No Loan Officer Found"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Search loan officer"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                           {
                                                showAddButton ?  
                                                    <Button
                                                         className="primary_btn"
                                                         variant="contained"
                                                         color="primary"
                                                         onClick={handleLoanOfficerDetails}
                                                         disabled={loAddDisable}
                                                     >
                                                         ADD
                                                     </Button> : ""
                                            }
                                            {
                                                (((forms?.loan_officer === null && forms?.is_loan_officer === true && confirmLOButtonDisplay === false && loStateChange === false) || (originatorChangeLoConfirm && forms?.is_loan_officer === true && loanOfficerState === true && loStateChange === false && formik.values.originatorName!=="")) &&
                                                        <Button
                                                            className="primary_btn"
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={(e) => { handleLoanOfficerConfirm(e) }}
                                                        >
                                                            Confirm
                                                        </Button>
                                                )
                                            }
                                        </Grid>
                                        {/* {
                                            ((forms?.loan_officer?.is_active === false && formik.values.loanOfficerFullName !== 'None' && formik.values.loanOfficerFullName == forms?.loan_officer?.calque_partner_contact_id) || (loanofficerErrorMsg !== '') || (isLoanOfficerActive !== '')) ?
                                                <Grid item xs={12} sm={12} md={12} lg={12} className="row formDiv">
                                                    <div className="error-msg" style={{ fontSize: '14px', height: '0px', paddingLeft: '0px', marginTop: '-25px' }}>Associated Loan Officer is no longer active</div>
                                                </Grid>
                                                :
                                                null
                                        } */}
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>First Name</label>
                                            <TextField
                                                autoComplete='off'
                                                variant="outlined"
                                                name="loanOfficerFirstName"
                                                disabled
                                                onChange={formik.handleChange}
                                                onBlur={(e) => (handleFirstName(e))}
                                                value={(formik.values.loanOfficerFirstName === "null") ? null : formik.values.loanOfficerFirstName}
                                                //formNovalidate
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Last Name</label>
                                            <TextField
                                                autoComplete='off'
                                                variant="outlined"
                                                disabled
                                                name="loanOfficerLastName"
                                                onChange={formik.handleChange}
                                                onBlur={(e) => (handleLastName(e))}
                                                value={(formik.values.loanOfficerLastName === "null") ? null : formik.values.loanOfficerLastName}
                                                //formNovalidate
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Phone Number</label>
                                            <NumberFormat
                                                id="phoneNumber"
                                                className={forms?.trade_in_application_id ? 'MuiInputBase-input MuiOutlinedInput-input Mui-disabled ' : 'MuiInputBase-input MuiOutlinedInput-input Mui-disabled '}
                                                disabled
                                                format="(###) ###-####"
                                                autoComplete='off'
                                                variant="outlined"
                                                name="loanOfficerPhoneNumber"
                                                allowEmptyFormatting
                                                onChange={(e) => phoneNumberFormat(e)}
                                                onFocus={props.handleChange}
                                                value={(formik.values.loanOfficerPhoneNumber === "" || formik.values.loanOfficerPhoneNumber === null || formik.values.loanOfficerPhoneNumber === "null") ? '' : formik.values.loanOfficerPhoneNumber}
                                                //formNovalidate
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Extension</label>
                                            <TextField
                                                autoComplete='off'
                                                variant="outlined"
                                                disabled="true"
                                                name="loanOfficerExtension"
                                                onChange={formik.handleChange}
                                                value={(formik.values.loanOfficerExtension === "" || formik.values.loanOfficerExtension === null || formik.values.loanOfficerExtension === "null") ? '' : formik.values.loanOfficerExtension}
                                                //formNovalidate
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Email ID</label>
                                            <TextField
                                                autoComplete='off'
                                                variant="outlined"
                                                disabled
                                                name="loanOfficerEmail"
                                                onChange={handleEmail, formik.handleChange}
                                                value={(formik.values.loanOfficerEmail === null || formik.values.loanOfficerEmail === "null") ? '' : formik.values.loanOfficerEmail}
                                                //formNovalidate
                                            />
                                        </Grid>
                                    </Grid>
                                    </Grid>
                                    <Grid container>
                                    <Grid xs={12} sm={12} md={6} lg={6} className="row formDiv radio-btn-div">
                                        <label className="radio-label">Real Estate Agent</label>
                                        <div className="d-flex justify-start align-center">
                                            <RadioGroup
                                                aria-label="real estate agent"
                                                name="is_real_estate_agent"
                                                className="d-flex justify-start align-center row-direction"
                                                value={realEstateAgent}
                                                onChange={(e) => handleRealEstateAgent(e.target.value)}
                                            >
                                                <div className="d-flex justify-start align-center row-direction">
                                                    <FormControlLabel disableripple className="mr-10 w-70" value="yes" control={<Radio disableripple color='primary' />} label="Yes" labelPlacement="end" />
                                                    <FormControlLabel disableripple className="mr-10 w-70" value="no" control={<Radio disableripple color='primary' />} label="No" labelPlacement="end" />
                                                </div>
                                            </RadioGroup>
                                        </div>
                                    </Grid>
                                    <Grid container className="row formDiv section-level-inner" style={realEstateAgent === 'yes' ? show : hide}>
                                        {
                                            handleRealEstateMsg()
                                        }
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Real Estate Agent Name<span className="red-color">*</span></label>
                                            <Autocomplete
                                                ref={ref}
                                                name='realEstateAgentFullName'
                                                popupIcon={false}
                                                onChangeCapture={handleSearchAgentValue}
                                                value={formik.values.realEstateAgentFullName}
                                                onChange={(event,newValue) => { handleChangeAgentValue(newValue) }}
                                                open={isAgentOpen}
                                                options={agentItems && agentItems.map((agent) => agent.calque_partner_contact_full_name)}
                                                getOptionLabel={(option) => (typeof option === 'string' ? option : "")}
                                                noOptionsText="No Real Estate Agent Found"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Search Real Estate Agent"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                           {
                                                showAddAgentButton ?  
                                                    <Button
                                                         className="primary_btn"
                                                         variant="contained"
                                                         color="primary"
                                                         onClick={handleRealEstateAgentDetails}
                                                         disabled={agentAddDisable}
                                                     >
                                                         ADD
                                                     </Button> : ""
                                            }
                                            {
                                                (((forms?.real_estate_agent === null && forms?.is_real_estate_agent === true && confirmRSAButtonDisplay === false && rsaStateChange === false) || (originatorChangeAgentConfirm && forms?.is_real_estate_agent === true && realEstateAgentState === true && rsaStateChange === false  && formik.values.originatorName!=="")) &&
                                                        <Button
                                                            className="primary_btn"
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={(e) => { handleRealEstateAgentConfirm(e) }}
                                                        >
                                                            Confirm
                                                        </Button>
                                                )
                                            }
                                        </Grid>
                                        {/* {((forms?.real_estate_agent?.is_active === false && formik.values.realEstateAgentFullName !== 'None' && formik.values.realEstateAgentFullName == forms?.real_estate_agent?.calque_partner_contact_id) || (realEstateAgentErrorMsg !== '') || (isRealEstateAgentActive !== '')) ?
                                            <Grid item xs={12} sm={12} md={12} lg={12} className="row formDiv">
                                                <div className="error-msg" style={{ fontSize: '14px', height: '0px', paddingLeft: '0px', marginTop: '-25px' }}>Associated Real Estate Agent is no longer active</div>
                                            </Grid>
                                            :
                                            null} */}
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>First Name</label>
                                            <TextField
                                                autoComplete='off'
                                                disabled
                                                type="text"
                                                variant="outlined"
                                                name="realEstateAgentFirstName"
                                                onChange={formik.handleChange}
                                                onBlur={(e) => { handleRealEstateAgentFirstName(e) }}
                                                value={(formik.values.realEstateAgentFirstName === "null") ? null : formik.values.realEstateAgentFirstName}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Last Name</label>
                                            <TextField
                                                autoComplete='off'
                                                disabled
                                                type="text"
                                                variant="outlined"
                                                name="realEstateAgentLastName"
                                                onChange={formik.handleChange}
                                                onBlur={(e) => { handleRealEstateAgentLastName(e) }}
                                                value={(formik.values.realEstateAgentLastName === "null") ? null : formik.values.realEstateAgentLastName}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Phone Number</label>
                                            <NumberFormat
                                                id="phoneNumber"
                                                className={forms?.trade_in_application_id ? 'MuiInputBase-input MuiOutlinedInput-input Mui-disabled ' : 'MuiInputBase-input MuiOutlinedInput-input Mui-disabled '}
                                                disabled
                                                format="(###) ###-####"
                                                autoComplete='off'
                                                variant="outlined"
                                                name="realEstateAgentPhoneNumber"
                                                allowEmptyFormatting
                                                onChange={(e) => phoneNumberFormatRealEstate(e)}
                                                onFocus={props.handleChange}
                                                value={(formik.values.realEstateAgentPhoneNumber === "" || formik.values.realEstateAgentPhoneNumber === null || formik.values.realEstateAgentPhoneNumber === "null") ? '' : formik.values.realEstateAgentPhoneNumber}
                                                formNovalidate
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Extension</label>
                                            <TextField
                                                autoComplete='off'
                                                variant="outlined"
                                                name="realEstateAgentExtension"
                                                disabled="true"
                                                onChange={formik.handleChange}
                                                value={(formik.values.realEstateAgentExtension === "" || formik.values.realEstateAgentExtension === null || formik.values.realEstateAgentExtension === "null") ? '' : formik.values.realEstateAgentExtension}
                                                formNovalidate
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Email ID</label>
                                            <TextField
                                                autoComplete='off'
                                                type="text"
                                                disabled
                                                variant="outlined"
                                                name="realEstateAgentEmail"
                                                onChange={handleRealEstateAgentEmail, formik.handleChange}
                                                value={(formik.values.realEstateAgentEmail === null || formik.values.realEstateAgentEmail === "null") ? '' : formik.values.realEstateAgentEmail}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv"></Grid>
                                    </Grid>
                                    </Grid>
                                    <Grid container xs={12} sm={12} md={6} lg={6} className="row formDiv radio-btn-div"></Grid>
                                </Grid>
                                {
                                    formik.touched.originatorName && formik.errors.originatorName ?
                                        <div className="error-msg">{formik.errors.originatorName}</div> 
                                        :
                                        <React.Fragment>
                                            {
                                                ((formik.touched.loanOfficerFullName && formik.errors.loanOfficerFullName) || (formik.touched.loanOfficerFirstName && formik.errors.loanOfficerFirstName) || (formik.touched.loanOfficerLastName && formik.errors.loanOfficerLastName) || (formik.touched.loanOfficerEmail && formik.errors.loanOfficerEmail)) && loanOfficer === "yes" ?
                                                    <div className="error-msg">{formik.errors.loanOfficerFirstName}</div>
                                                    :
                                                    <React.Fragment>
                                                        {
                                                          ((formik.touched.realEstateAgentFullName && formik.errors.realEstateAgentFullName) || (formik.touched.realEstateAgentFirstName && formik.errors.realEstateAgentFirstName) || (formik.touched.realEstateAgentLastName && formik.errors.realEstateAgentLastName)) && realEstateAgent === "yes" ?
                                                            <div className="error-msg">{formik.errors.realEstateAgentFirstName}</div>
                                                            :  ''
                                                        }
                                                    </React.Fragment>
                                            }
                                        </React.Fragment>
                                }
                                <h3 className="section-title">Homeowner Information</h3>
                                <Grid container className="section-level">
                                    <Grid container >
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>First Name<span className="red-color">*</span></label>
                                        <TextField
                                            autoComplete='off'
                                            type="text"
                                            variant="outlined"
                                            name="borrowerFirstName"
                                            onChange={formik.handleChange}
                                            value={formik.values.borrowerFirstName.trimStart()}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Last Name<span className="red-color">*</span></label>
                                        <TextField
                                            autoComplete='off'
                                            type="text"
                                            variant="outlined"
                                            name="borrowerLastName"
                                            onChange={formik.handleChange}
                                            value={formik.values.borrowerLastName.trimStart()}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Phone Number<span className="red-color">*</span></label>
                                        <NumberFormat
                                            id="phoneNumber"
                                            className='MuiInputBase-input MuiOutlinedInput-input '
                                            format="(###) ###-####"
                                            autoComplete='off'
                                            variant="outlined"
                                            name="borrowerPhoneNumber"
                                            allowEmptyFormatting
                                            onChange={(e) => phoneNumberFormatBorrower(e)}
                                            onFocus={props.handleChange}
                                            value={formik.values.borrowerPhoneNumber}
                                            formNovalidate
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Extension</label>
                                        <NumberFormat
                                            id="extension"
                                            className='numberField'
                                            autoComplete='off'
                                            variant="outlined"
                                            name="homeOwnerExtension"
                                            onChange={formik.handleChange}
                                            value={(formik.values.homeOwnerExtension === "" || formik.values.homeOwnerExtension === null || formik.values.homeOwnerExtension === "null") ? null : formik.values.homeOwnerExtension}
                                            formNovalidate
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Email ID<span className="red-color">*</span></label>
                                        <TextField
                                            autoComplete='off'
                                            type="text"
                                            variant="outlined"
                                            name="borrowerEmail"
                                            onChange={formik.handleChange}
                                            value={formik.values.borrowerEmail.trimStart().trimEnd()}
                                        />
                                    </Grid>
                                    </Grid>
                                    <Grid container xs={12} sm={12} md={6} lg={6} className="row formDiv radio-btn-div">
                                        <label className="radio-label">Additional Homeowner</label>
                                        <div className="d-flex justify-start align-center">
                                            <RadioGroup
                                                aria-label="co-borrower"
                                                name="co-borrower"
                                                className="d-flex justify-start align-center row-direction"
                                                value={coBorrower}
                                                onChange={(e) => handleCoBorrowerChange(e.target.value)}
                                            >
                                                <div className="d-flex justify-start align-center row-direction">
                                                    <FormControlLabel disableripple className="mr-10 w-70" value="yes" control={<Radio disableripple color='primary' />} label="Yes" labelPlacement="end" />
                                                    <FormControlLabel disableripple className="mr-10 w-70" value="no" control={<Radio disableripple color='primary' />} label="No" labelPlacement="end" />
                                                </div>
                                            </RadioGroup>
                                        </div>
                                    </Grid>
                                    <Grid container>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv" style={coBorrower === 'yes' ? show : hide}>
                                        <label>First Name<span className="red-color">*</span></label>
                                        <TextField
                                            autoComplete='off'
                                            type="text"
                                            variant="outlined"
                                            name="coBorrowerFirstName"
                                            onChange={formik.handleChange}
                                            value={formik.values.coBorrowerFirstName && formik.values.coBorrowerFirstName.trimStart()}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv" style={coBorrower === 'yes' ? show : hide}>
                                        <label>Last Name<span className="red-color">*</span></label>
                                        <TextField
                                            autoComplete='off'
                                            type="text"
                                            variant="outlined"
                                            name="coBorrowerLastName"
                                            onChange={formik.handleChange}
                                            value={formik.values.coBorrowerLastName && formik.values.coBorrowerLastName.trimStart()}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv" style={coBorrower === 'yes' ? show : hide}>
                                        <label>Phone Number</label>
                                        <NumberFormat
                                            id="phoneNumber"
                                            className='MuiInputBase-input MuiOutlinedInput-input '
                                            format="(###) ###-####"
                                            autoComplete='off'
                                            variant="outlined"
                                            name="coBorrowerPhoneNumber"
                                            allowEmptyFormatting
                                            onChange={(e) => phoneNumberCoBorrower(e)}
                                            onFocus={props.handleChange}
                                            value={formik.values.coBorrowerPhoneNumber}
                                            formNovalidate
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv" style={coBorrower === 'yes' ? show : hide}>
                                        <label>Extension</label>
                                        <NumberFormat
                                            className='numberField'
                                            autoComplete='off'
                                            variant="outlined"
                                            name="additionalHomeOwnerExtension"
                                            onChange={formik.handleChange}
                                            value={(formik.values.additionalHomeOwnerExtension === "" || formik.values.additionalHomeOwnerExtension === null || formik.values.additionalHomeOwnerExtension === "null") ? '' : formik.values.additionalHomeOwnerExtension}
                                            formNovalidate
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv" style={coBorrower === 'yes' ? show : hide}>
                                        <label>Email ID<span className="red-color">*</span></label>
                                        <TextField
                                            autoComplete='off'
                                            type="text"
                                            variant="outlined"
                                            name="coBorrowerEmail"
                                            onChange={formik.handleChange}
                                            value={formik.values.coBorrowerEmail && formik.values.coBorrowerEmail.trimStart().trimEnd()}
                                        />
                                    </Grid>
                                    </Grid>
                                </Grid>
                                {
                                    formik.touched.borrowerFirstName && (radioErr != 'no' && formik.errors.borrowerFirstName) ?
                                        <div className="error-msg">{formik.errors.borrowerFirstName}</div>
                                        :
                                        <React.Fragment>
                                            {
                                                formik.touched.borrowerEmail && formik.errors.borrowerEmail ?
                                                    <div className="error-msg">{formik.errors.borrowerEmail}</div>
                                                    :
                                                    <React.Fragment>
                                                        {
                                                            formik.touched.coBorrowerEmail && formik.errors.coBorrowerEmail && coBorrower === "yes" ?
                                                                <div className="error-msg">{formik.errors.coBorrowerEmail}</div> : ''
                                                        }
                                                    </React.Fragment>
                                            }
                                        </React.Fragment>
                                }
                                <h3 className="section-title">Existing Property Information</h3>
                                <Grid container className="section-level">
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Street Address<span className="red-color">*</span></label>
                                        <TextField
                                            autoComplete='off'
                                            type="text"
                                            variant="outlined"
                                            name="propertyInfoStreet"
                                            onChange={formik.handleChange}
                                            value={formik.values.propertyInfoStreet.trimStart()}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Zip code<span className="red-color">*</span></label>
                                        <TextField
                                            autoComplete='off'
                                            type="text"
                                            maxLength={5}
                                            variant="outlined"
                                            name="propertyInfoZipcode"
                                            onBlur={formik.handleBlur}
                                            onKeyDown={(e) => restrictNumber(e)}
                                            onChange={(e) => handleZipcode(e.target.value)}
                                            value={zipCodeValue}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>City<span className="red-color">*</span></label>
                                        <TextField
                                            autoComplete='off'
                                            type="text"
                                            variant="outlined"
                                            name="propertyInfoCity"
                                            onChange={formik.handleChange}
                                            value={formik.values.propertyInfoCity && formik.values.propertyInfoCity.trimStart()}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>County<span className="red-color">*</span></label>
                                        <div class="views-widget" style={{marginLeft:"4px"}}>
                                            <select
                                                name='propertyInfoCounty'
                                                className="form_select form-select-mgp"
                                                style={{ color: '#000' }}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                value={formik.values.propertyInfoCounty}
                                            >
                                                <option value="">---Select Country---</option>
                                                {countyList.map((item, index) => (
                                                    <option key={index} value={item.county_id}  >
                                                        {item.county_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>State<span className="red-color">*</span></label>
                                        <div class="views-widget" style={{marginLeft:"4px"}}>
                                            <select
                                                name='propertyInfoState'
                                                className="form_select form-select-mgp"
                                                style={{ color: '#000' }}
                                                onChange={formik.handleChange}
                                                value={formik.values.propertyInfoState}
                                            >
                                                <option value="">---Select State---</option>
                                                {stateList.map((item, index) => (
                                                    <option key={index} value={item.state_id} >
                                                        {item.state_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Property Type<span className="red-color">*</span></label>
                                        <div class="views-widget" style={{marginLeft:"4px"}}>
                                            <select
                                                name='propertyInfoProType'
                                                className="form_select form-select-mgp"
                                                style={{ color: '#000' }}
                                                onChange={formik.handleChange}
                                                value={formik.values.propertyInfoProType}
                                            >
                                                <option value="">---Select Property Type---</option>
                                                {propertyTypeList.map((item, index) => (
                                                    <option key={index} value={item.property_type_id} >
                                                        {item.property_type}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </Grid>
                                    {Number(formik?.values?.propertyInfoProType) === 3 ?
                                    <>
                                    <Grid item xs={12} sm={12} md={12} lg={12} className="row formDiv">
                                        <label>Condo Unit Number</label>
                                        <TextField
                                        autoComplete='off'
                                        type="text"
                                        variant="outlined"
                                        name="condoUnitNum"
                                        onChange={formik.handleChange}
                                        value={formik.values.condoUnitNum}
                                        inputProps={{ maxLength: 10 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} className="row formDiv">
                                        <label>Condo Assoc. Name</label>
                                        <TextField  
                                        autoComplete='off'
                                        type="text"
                                        name="condoAssocName"
                                        onChange={formik.handleChange}
                                        value={formik.values.condoAssocName}
                                        multiline
                                        rows={4}
                                        />
                                    </Grid>
                                    </>:<></>}
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv radio-btn-div">
                                            <label className="radio-label">Is Owner<span className="red-color">*</span></label>
                                            <div className="d-flex justify-start align-center">
                                                <RadioGroup
                                                    aria-label="propertyInfoInsurance"
                                                    name="isOwner"
                                                    className="d-flex justify-start align-center row-direction"
                                                    value={isOwner}
                                                    onChange={handleOwnerOrNot}
                                                >
                                                    <div className="d-flex justify-start align-center row-direction">
                                                        <FormControlLabel disableripple className="mr-10 w-70" value="true" control={<Radio disableripple color='primary' />} label="Yes" labelPlacement="end" />
                                                        <FormControlLabel disableripple className="mr-10 w-70" value="false" control={<Radio disableripple color='primary' />} label="No" labelPlacement="end" />
                                                    </div>
                                                </RadioGroup>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    {isOwner === 'true' ?
                                    <Grid container className="row formDiv section-level-inner" style={{ marginBottom:"20px" }}>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv radio-btn-div">
                                            <label className="radio-label">Primary Residence<span className="red-color">*</span></label>
                                            <div className="d-flex justify-start align-center">
                                                <RadioGroup
                                                    aria-label="primaryResidence"
                                                    name="primaryResidence"
                                                    className="d-flex justify-start align-center row-direction"
                                                    style={{ backgroundColor:"#fff" }}
                                                    value={primaryResidence}
                                                    onChange={handlePrimaryResidence}
                                                >
                                                    <div className="d-flex justify-start align-center row-direction" style={{ backgroundColor:"#fff" }}>
                                                        <FormControlLabel disableripple className="mr-10 w-70" value="true" control={<Radio disableripple color='primary' />} label="Yes" labelPlacement="end" />
                                                        <FormControlLabel disableripple className="mr-10 w-70" value="false" control={<Radio disableripple color='primary' />} label="No" labelPlacement="end" />
                                                    </div>
                                                </RadioGroup>
                                            </div>
                                        </Grid>
                                        {primaryResidence === 'false' && isOwner === 'true' ?
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label className="radio-label">Comments</label>
                                            <TextareaAutosize
                                                style={{ width: "100% " }}
                                                placeholder='Enter non-residents comments.'
                                                className="form_select comment-area"
                                                autoComplete='off'
                                                type="text"
                                                variant="outlined"
                                                name="primaryResidenceComments"
                                                onChange={formik.handleChange}
                                                value={formik.values.primaryResidenceComments && formik.values.primaryResidenceComments.trimStart()}
                                            />
                                        </Grid>:''}
                                    </Grid>:
                                    isOwner === 'false'?
                                    <Grid container style={{ paddingLeft:"8px" }}>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <TextareaAutosize
                                                style={{ width: "248px !important" }}
                                                placeholder='Enter non-owner comments.'
                                                className="form_select comment-area"
                                                autoComplete='off'
                                                type="text"
                                                variant="outlined"
                                                name="nonOwnerComments"
                                                onChange={formik.handleChange}
                                                value={formik.values.nonOwnerComments && formik.values.nonOwnerComments.trimStart()}
                                            />
                                        </Grid>
                                    </Grid>:''}
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Purchase Date</label>
                                        <KeyboardDatePicker
                                            autoOk
                                            className="date-input"
                                            format="MM/dd/yyyy"
                                            disableFuture={true}
                                            placeholder="MM/DD/YYYY"
                                            name="propertyInfoPurchase"
                                            onChange={handlePurchaseDateChange}
                                            value={formik.values.propertyInfoPurchase}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Purchase Price</label>
                                        <NumberFormat
                                            className="numberField"
                                            thousandSeparator={','}
                                            decimalSeparator={'.'}
                                            autoComplete='off'
                                            decimalScale={2}
                                            maxLength="13"
                                            variant="outlined"
                                            name="propertyInfoPrice"
                                            onChange={formik.handleChange}
                                            value={formik.values.propertyInfoPrice === '.' ? 0 : formik.values.propertyInfoPrice}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Owner Estimated Value<span className="red-color">*</span></label>
                                        <NumberFormat
                                            className="numberField"
                                            thousandSeparator={','}
                                            decimalSeparator={'.'}
                                            decimalScale={2}
                                            autoComplete='off'
                                            maxLength="13"
                                            variant="outlined"
                                            name="ownerEstValue"
                                            onChange={formik.handleChange}
                                            value={formik.values.ownerEstValue === ('.' || null) ? 0 : formik.values.ownerEstValue}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Equity Requested for New Home</label>
                                        <NumberFormat
                                            className="numberField"
                                            thousandSeparator={','}
                                            decimalSeparator={'.'}
                                            decimalScale={2}
                                            autoComplete='off'
                                            maxLength="13"
                                            variant="outlined"
                                            name="equity_unlock_requested"
                                            onChange={formik.handleChange}
                                            value={formik.values.equity_unlock_requested === ('.' || null) ? 0 : formik.values.equity_unlock_requested}
                                        />
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv radio-btn-div">
                                            <label className="radio-label">1st Mortgage<span className="red-color">*</span></label>
                                            <div className="d-flex justify-start align-center">
                                                <RadioGroup
                                                    aria-label="propertyInfoInsurance"
                                                    name="mortgageInfoSecond"
                                                    className="d-flex justify-start align-center row-direction"
                                                    value={mortgageFirst}
                                                    onChange={(e) => handleMortgageFirst(e.target.value)}
                                                >
                                                    <div className="d-flex justify-start align-center row-direction">
                                                        <FormControlLabel disableripple className="mr-10 w-70" value="true" control={<Radio disableripple color='primary' />} label="Yes" labelPlacement="end" />
                                                        <FormControlLabel disableripple className="mr-10 w-70" value="false" control={<Radio disableripple color='primary' />} label="No" labelPlacement="end" />
                                                    </div>
                                                </RadioGroup>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container className="row formDiv section-level-inner" style={mortgageFirst === 'true' ? show : hide}>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Est. Mortgage Balance<span className="red-color">*</span></label>
                                            <NumberFormat
                                                className="numberField"
                                                thousandSeparator={','}
                                                decimalSeparator={'.'}
                                                autoComplete='off'
                                                decimalScale={2}
                                                maxLength="13"
                                                variant="outlined"
                                                name="estMortgage1Balance"
                                                onChange={formik.handleChange}
                                                value={formik.values.estMortgage1Balance}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv radio-btn-div">
                                        <label className="radio-label">Property Taxes Escrowed?</label>
                                        <div className="d-flex justify-start align-center">
                                            <RadioGroup
                                                aria-label="co-borrower"
                                                name="mortgageInfoTax"
                                                className="d-flex justify-start align-center"
                                                value={formik.values.mortgageInfoTax}
                                                onChange={formik.handleChange}
                                            >
                                                <div className="d-flex justify-start align-center">
                                                    <FormControlLabel disableripple className="mr-10 w-70" value="true" control={<Radio disableripple color='primary' />} label="Yes" labelPlacement="end" />
                                                    <FormControlLabel disableripple className="mr-10 w-70" value="false" control={<Radio disableripple color='primary' />} label="No" labelPlacement="end" />
                                                </div>
                                            </RadioGroup>
                                        </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Monthly Payment<span className="red-color">*</span></label>
                                            <NumberFormat
                                                className="numberField"
                                                thousandSeparator={','}
                                                decimalSeparator={'.'}
                                                autoComplete='off'
                                                decimalScale={2}
                                                maxLength="13"
                                                variant="outlined"
                                                name="mort1MonthlyPayment"
                                                onChange={formik.handleChange}
                                                value={formik.values.mort1MonthlyPayment}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv radio-btn-div">
                                        <label className="radio-label">Insurance Escrowed?</label>
                                        <div className="d-flex justify-start align-center">
                                            <RadioGroup
                                                aria-label="co-borrower"
                                                name="mortgageInfoInsurance"
                                                className="d-flex justify-start align-center"
                                                value={formik.values.mortgageInfoInsurance}
                                                onChange={formik.handleChange}
                                            >
                                                <div className="d-flex justify-start align-center">
                                                    <FormControlLabel disableripple className="mr-10 w-70" value="true" control={<Radio disableripple color='primary' />} label="Yes" labelPlacement="end" />
                                                    <FormControlLabel disableripple className="mr-10 w-70" value="false" control={<Radio disableripple color='primary' />} label="No" labelPlacement="end" />
                                                </div>
                                            </RadioGroup>
                                        </div>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv radio-btn-div">
                                        <label className="radio-label">2nd Mortgage<span className="red-color"></span></label>
                                        <div className="d-flex justify-start align-center">
                                            <RadioGroup
                                                aria-label="propertyInfoInsurance"
                                                name="mortgageInfoSecond"
                                                className="d-flex justify-start align-center row-direction"
                                                value={mortgageSecond}
                                                onChange={(e) => handleMortgageSecond(e.target.value)}
                                            >
                                                <div className="d-flex justify-start align-center row-direction">
                                                    <FormControlLabel disableripple className="mr-10 w-70" value="true" control={<Radio disableripple color='primary' />} label="Yes" labelPlacement="end" />
                                                    <FormControlLabel disableripple className="mr-10 w-70" value="false" control={<Radio disableripple color='primary' />} label="No" labelPlacement="end" />
                                                </div>
                                            </RadioGroup>
                                        </div>
                                    </Grid>
                                    <Grid container xs={12} sm={12} md={6} lg={6} className="row formDiv radio-btn-div"></Grid>
                                    <Grid container className="row formDiv section-level-inner" style={mortgageSecond === 'true' ? show : hide}>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Loan Type</label>
                                            <div class="views-widget" style={{marginLeft:"3px"}}>
                                                <select
                                                    name="mortgageInfoSecondLoanType"
                                                    className="form_select"
                                                    style={{ color: '#000' }}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.mortgageInfoSecondLoanType}
                                                >
                                                    <option value="">---Select Loan Type---</option>
                                                    {loanType.map((item, index) => (
                                                        <option key={index} value={item.mortgage_type_id} >
                                                            {item.mortgage_type}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Est. Mortgage Balance</label>
                                            <NumberFormat
                                                className="numberField"
                                                thousandSeparator={','}
                                                decimalSeparator={'.'}
                                                autoComplete='off'
                                                decimalScale={2}
                                                maxLength="13"
                                                variant="outlined"
                                                name="estMortgage2Balance"
                                                onChange={formik.handleChange}
                                                value={formik.values.estMortgage2Balance}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Monthly Payment</label>
                                            <NumberFormat
                                                className="numberField"
                                                thousandSeparator={','}
                                                decimalSeparator={'.'}
                                                autoComplete='off'
                                                decimalScale={2}
                                                maxLength="13"
                                                variant="outlined"
                                                name="mort2MonthlyPayment"
                                                onChange={formik.handleChange}
                                                value={formik.values.mort2MonthlyPayment}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv radio-btn-div">
                                        <label className="radio-label">Other Loans or Liens?<span className="red-color"></span></label>
                                        <div className="d-flex justify-start align-center">
                                            <RadioGroup
                                                aria-label="propertyInfoInsurance"
                                                name="loansInfo"
                                                className="d-flex justify-start align-center row-direction"
                                                value={loansInfo}
                                                onChange={(e) => handleLoansInfo(e.target.value)}
                                            >
                                                <div className="d-flex justify-start align-center row-direction">
                                                    <FormControlLabel disableripple className="mr-10 w-70" value="true" control={<Radio disableripple color='primary' />} label="Yes" labelPlacement="end" />
                                                    <FormControlLabel disableripple className="mr-10 w-70" value="false" control={<Radio disableripple color='primary' />} label="No" labelPlacement="end" />
                                                </div>
                                            </RadioGroup>
                                        </div>
                                    </Grid>
                                    <Grid container xs={12} sm={12} md={6} lg={6} className="row formDiv radio-btn-div"></Grid>
                                    <Grid container className="row formDiv" style={loansInfo === 'true' ? show : hide}>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <TextareaAutosize
                                                style={{ width: "100% " }}
                                                placeholder='Enter notes about additional loans here.'
                                                className="form_select comment-area"
                                                autoComplete='off'
                                                type="text"
                                                variant="outlined"
                                                name="mort_other_loans"
                                                onChange={formik.handleChange}
                                                value={formik.values.mort_other_loans && formik.values.mort_other_loans.trimStart()}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv radio-btn-div">
                                        <label className="radio-label">Other Fees or Dues?<span className="red-color"></span></label>
                                        <div className="d-flex justify-start align-center">
                                            <RadioGroup
                                                aria-label="propertyInfoInsurance"
                                                name="otherFeeInfo"
                                                className="d-flex justify-start align-center row-direction"
                                                value={otherFeeInfo}
                                                onChange={(e) => handleOtherFee(e.target.value)}
                                            >
                                                <div className="d-flex justify-start align-center row-direction">
                                                    <FormControlLabel disableripple className="mr-10 w-70" value="true" control={<Radio disableripple color='primary' />} label="Yes" labelPlacement="end" />
                                                    <FormControlLabel disableripple className="mr-10 w-70" value="false" control={<Radio disableripple color='primary' />} label="No" labelPlacement="end" />
                                                </div>
                                            </RadioGroup>
                                        </div>
                                    </Grid>
                                    <Grid container xs={12} sm={12} md={6} lg={6} className="row formDiv radio-btn-div"></Grid>
                                    <Grid container className="row formDiv section-level-inner" style={otherFeeInfo === 'true' ? show : hide}>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Describe Fees</label>
                                            <TextField
                                                autoComplete='off'
                                                variant="outlined"
                                                name="mortDescribeFees"
                                                onChange={formik.handleChange}
                                                value={formik.values.mortDescribeFees}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Monthly Amount</label>
                                            <NumberFormat
                                                className="numberField"
                                                thousandSeparator={','}
                                                decimalSeparator={'.'}
                                                autoComplete='off'
                                                decimalScale={2}
                                                maxLength="13"
                                                variant="outlined"
                                                name="mortOtherFeesMonthlyAmount"
                                                onChange={formik.handleChange}
                                                value={formik.values.mortOtherFeesMonthlyAmount}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {
                                    formik.touched.propertyInfoStreet && formik.errors.propertyInfoStreet && displayPropertyError === true ?
                                        <div className="error-msg">{formik.errors.propertyInfoStreet}</div>
                                        :
                                        formik.touched.estMortgage1Balance && formik.errors.estMortgage1Balance && mortgageFirst === 'true' ?
                                        <div className="error-msg">{formik.errors.estMortgage1Balance}</div>
                                        :
                                        formik.touched.mort1MonthlyPayment && formik.errors.mort1MonthlyPayment && mortgageSecond === 'true' ?
                                        <div className="error-msg">{formik.errors.mort1MonthlyPayment}</div>
                                        :
                                        formik.touched.mort_other_loans && formik.errors.mort_other_loans && loansInfo === 'true' ?
                                        <div className="error-msg">{formik.errors.mort_other_loans}</div>
                                        :
                                        formik.touched.mortOtherFeesMonthlyAmount && formik.touched.mortDescribeFees && formik.errors.mortDescribeFees && otherFeeInfo === 'true' ?
                                        <div className="error-msg">{formik.errors.mortDescribeFees}</div>
                                        :
                                        <React.Fragment>
                                            {
                                                formik.touched.propertyInfoPurchase && formik.errors.propertyInfoPurchase ?
                                                    <div className="error-msg">{formik.errors.propertyInfoPurchase}</div> : ''
                                            }
                                        </React.Fragment>
                                }
                                <h3 className="section-title">Additional Information</h3>
                                <Grid container className="section-level">
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Current home condition</label>
                                        <div class="views-widget" style={{marginLeft:"4px"}}>
                                            <select
                                                name="homeCondition"
                                                className="form_select"
                                                style={{ color: '#000' }}
                                                onChange={formik.handleChange}
                                                value={formik.values.homeCondition}
                                            >
                                                <option value="">---Select Condition---</option>
                                                {propertyHome.map((item, index) => (
                                                    <option key={index} value={item.home_condition_id} >
                                                        {item.home_condition}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>How did you hear about us?</label>
                                        <div class="views-widget">
                                            <select
                                                name="hearAboutUs"
                                                className="form_select"
                                                style={{ color: '#000' }}
                                                onChange={formik.handleChange}
                                                value={formik.values.hearAboutUs}
                                            >
                                                <option value="">---Select---</option>
                                                {hearAboutUs.map((item, index) => (
                                                    <option key={index} value={item.hear_about_us_option_id} >
                                                        {item.hear_about_us_option_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Above Grade Sq Ft</label>
                                        <NumberFormat
                                            className="numberField"
                                            thousandSeparator={','}
                                            decimalSeparator={'.'}
                                            autoComplete='off'
                                            decimalScale={2}
                                            maxLength="13"
                                            variant="outlined"
                                            name="aboveGradeSqft"
                                            onChange={formik.handleChange}
                                            value={formik.values.aboveGradeSqft === '.' ? 0 : formik.values.aboveGradeSqft}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Biggest Concern</label>
                                        <div class="views-widget">
                                            <select
                                                name="biggestConcern"
                                                className="form_select"
                                                style={{ color: '#000',whiteSpace:"nowrap",textOverflow:"ellipsis" }}
                                                onChange={formik.handleChange}
                                                value={formik.values.biggestConcern}
                                            >
                                                <option value="">---Select---</option>
                                                {concern.map((item, index) => (
                                                    <option key={index} value={item.property_reason_for_sale_id} >
                                                        {item.reason_for_sale}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Year Built</label>
                                        <TextField
                                            type="text"
										    variant="outlined"
                                            name="yearBuilt"
                                            onChange={formik.handleChange}
                                            value={formik.values.yearBuilt < currentTime.getFullYear() + 1 ? formik.values.yearBuilt : ''}
                                            onBlur={formik.handleBlur}
                                            inputprops={{
										    	pattern: "[0-9]{4}",
										    	maxlength: "4",
										    	min: "1800",
										    	max: '2100'
										    }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Selling Time Frame</label>
                                        <div class="views-widget">
                                            <select
                                                name="readyToSell"
                                                className="form_select"
                                                style={{ color: '#000' }}
                                                onChange={formik.handleChange}
                                                value={formik.values.readyToSell}
                                            >
                                                <option value="">---Select---</option>
                                                {sellingTimeFrame.map((item, index) => (
                                                    <option key={index} value={item.property_ready_to_sell_id} >
                                                        {item.ready_to_sell}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Max New Home Price</label>
                                        <NumberFormat
                                            className="numberField"
                                            thousandSeparator={','}
                                            decimalSeparator={'.'}
                                            autoComplete='off'
                                            decimalScale={2}
                                            maxLength="13"
                                            variant="outlined"
                                            name="maxHomePrice"
                                            onChange={formik.handleChange}
                                            value={formik.values.maxHomePrice === '.' ? 0 : formik.values.maxHomePrice}

                                        />
                                    </Grid>
                                </Grid>
                                {
                                    formik?.touched?.yearBuilt && formik.errors?.yearBuilt ?
                                        <div className="error-msg">{formik.errors?.yearBuilt}</div> 
                                        : ""
                                }
                                <h3 className="section-title">Property Disclosure</h3>
                                <Grid container className="section-level">
                                    <Grid container xs={12} sm={12} md={12} lg={12} className="row formDiv radio-btn-div">
                                        <label className="radio-label">Are you aware of any significant defects or malfunctions in any of the following?</label>
                                    </Grid>
                                   <Grid container className="row formDiv" style={{ height:"245px",paddingLeft:"2%",paddingTop:"0px", display:"block" }}>
                                    <div><p style={{ fontSize:"14px" }}>if yes, check appropriate boxes below</p></div>
                                    <FormGroup className="defects-grp" style={{ height:"100%" }}>
                                    {defectsList && defectsList.map((defect, index) => (
                                        <div key={index}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkedBoxes?.includes(defect.property_defect_id.toString())}
                                                        onChange={handleCheckboxChange}
                                                        value={defect.property_defect_id.toString()}
                                                        color="primary"
                                                    />
                                                }
                                                label={defect.property_defect_name}
                                            />
                                        </div>
                                    ))}
                                    </FormGroup> 
                                    </Grid>
                                    <Grid container style={{paddingTop:"20px",paddingBottom:"0px"}} xs={12} sm={12} md={12} lg={12} className="row formDiv radio-btn-div">
                                        <label className="radio-label">Are you aware of any of the following?</label>
                                    <ol style={{ fontSize:"14px",marginBottom:"0px" }}>
                                        {conditionList && conditionList.map((condition,index)=>{
                                            const alterationValue = forms?.existing_property?.alterations_and_problems?.[condition.property_condition_detail_name];
                                            return(
                                                <>
                                                <li key={condition.property_condition_details_id}>{condition.property_condition_detail_name}</li>
                                                <div className="py-2">
                                                    <RadioGroup
                                                        row 
                                                        name="other_issues"
                                                        defaultValue={alterationValue?.toString()}
                                                    >
                                                        <div className="d-flex justify-start align-center row-direction">
                                                            <FormControlLabel disableripple className="mr-10 w-70" value="true" control={<Radio disableripple color='primary' />} label="Yes" labelPlacement="end" onChange={() => handleRadioChange(condition.property_condition_detail_name, "true")}/>
                                                            <FormControlLabel disableripple className="mr-10 w-70" value="false" control={<Radio disableripple color='primary' />} label="No" labelPlacement="end" onChange={() => handleRadioChange(condition.property_condition_detail_name, "false")}/>
                                                        </div>
                                                    </RadioGroup>
                                                </div>
                                                </>
                                            )
                                        })}
                                    </ol>
                                    </Grid>
                                    <Grid container style={{paddingTop:"0px"}} xs={12} sm={12} md={6} lg={6} className="row formDiv radio-btn-div">
                                        <label className="radio-label">Does your home have a septic system?</label>
                                        <div className="d-flex justify-start align-center">
                                            <RadioGroup
                                                aria-label="septicSystem"
                                                name="septicSystem"
                                                className="d-flex justify-start align-center row-direction"
                                                value={formik.values.septicSystem}
                                                onChange={formik.handleChange}
                                            >
                                                <div className="d-flex justify-start align-center row-direction">
                                                    <FormControlLabel disableripple className="mr-10 w-70" value="true" control={<Radio disableripple color='primary' />} label="Yes" labelPlacement="end" />
                                                    <FormControlLabel disableripple className="mr-10 w-70" value="false" control={<Radio disableripple color='primary' />} label="No" labelPlacement="end" />
                                                </div>
                                            </RadioGroup>
                                        </div>
                                    </Grid>
                                </Grid>
                                <h3 className="section-title">Takeout Offer Information</h3>
                                <Grid container className="section-level">
                                    <Grid container xs={12} sm={12} md={6} lg={6} className="row formDiv radio-btn-div">
                                        <label className="radio-label">Do You Have Takeout Offer?<span className="red-color">*</span></label>
                                        <div className="d-flex justify-start align-center">
                                            <RadioGroup
                                                aria-label="takeOutOffer"
                                                name="takeOutOffer"
                                                className="d-flex justify-start align-center row-direction"
                                                value={takeOutOffer}
                                                onChange={(e) => handletakeOutOffer(e.target.value)}
                                            >
                                                <div className="d-flex justify-start align-center row-direction">
                                                    <FormControlLabel disableripple className="mr-10 w-70" value="yes" control={<Radio disableripple color='primary' />} label="Yes" labelPlacement="end" />
                                                    <FormControlLabel disableripple className="mr-10 w-70" value="no" control={<Radio disableripple color='primary' />} label="No" labelPlacement="end" />
                                                </div>
                                            </RadioGroup>
                                        </div>
                                    </Grid>
                                    <Grid container xs={12} sm={12} md={6} lg={6} className="row formDiv radio-btn-div"></Grid>
                                    {takeOutOffer === 'yes' && (
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Takeout Buyer Offer<span className="red-color">*</span></label>
                                            <NumberFormat
                                                className="numberField"
                                                thousandSeparator={','}
                                                decimalSeparator={'.'}
                                                autoComplete='off'
                                                decimalScale={2}
                                                maxLength="13"
                                                variant="outlined"
                                                name="takeOutBuyer"
                                                onChange={formik.handleChange}
                                                value={formik.values.takeOutBuyer}
                                            />
                                        </Grid>)
                                    }

                                    {takeOutOffer === 'no' && (
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Takeout Offer Percentage<span className="red-color">*</span></label>
                                            <NumberFormat
                                                name="takeOutPercentage"
                                                id='takeOutPercentage'
                                                className="numberField"
                                                thousandSeparator={','}
                                                decimalSeparator={'.'}
                                                autoComplete='off'
                                                decimalScale={2}
                                                allowNegative={false}
                                                inputMode="numeric"
                                                placeholder='0'
                                                allowEmptyFormatting
                                                maxLength={6}
                                                value={formik.values.takeOutPercentage}
                                                onChange={formik.handleChange}
                                                isAllowed={(values) => {
                                                    const { floatValue } = values;
                                                    return (floatValue >= 0 && floatValue <= 100) || !floatValue;
                                                }}
                                            />
                                        </Grid>)
                                    }
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Calque Fee Percentage<span className="red-color">*</span></label>
                                        <NumberFormat
                                            name="calqueFee"
                                            id='calqueFee'
                                            className="numberField"
                                            thousandSeparator={','}
                                            decimalSeparator={'.'}
                                            autoComplete='off'
                                            decimalScale={2}
                                            allowNegative={false}
                                            inputMode="numeric"
                                            placeholder='0'
                                            allowEmptyFormatting
                                            maxLength={6}
                                            value={formik.values.calqueFee}
                                            onChange={formik.handleChange}
                                            isAllowed={(values) => {
                                                const { floatValue } = values;
                                                return (floatValue >= 0 && floatValue <= 100) || !floatValue;
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Administrative Fee<span className="red-color">*</span></label>
                                        <NumberFormat
                                            className="numberField"
                                            thousandSeparator={','}
                                            decimalSeparator={'.'}
                                            autoComplete='off'
                                            id='administrative_fee'
                                            decimalScale={2}
                                            maxLength="13"
                                            variant="outlined"
                                            name="administrative_fee"
                                            onChange={formik.handleChange}
                                            value={formik.values.administrative_fee}
                                            onKeyDown={(e) => restrictDecimal(e, "administrative_fee")}
                                        />
                                    </Grid>
                                </Grid>
                                {
                                    formik.touched.calqueFee && formik.errors.calqueFee ?
                                        <div className="error-msg">{formik.errors.calqueFee}</div> : ''
                                }
                                <Grid container className="form_layout_btn">
                                    <Button
                                        className="primary_btn"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={mgpLoader === true}
                                    >
                                        {mgpLoader === false ? 'Save' : <CircularProgress className='circular_spiner' size={20} color='white' />}
                                    </Button>
                                    {(forms?.trade_in_application_id && forms?.is_test_app === false && isTest === false ) || (isTest === false) || ((forms?.trade_in_application_id && forms?.is_test_app === false) && isTest === null) ?
                                        <Button
                                            className="primary_btn"
                                            variant="contained"
                                            style={{width:"180px"}}
                                            color="primary"
                                            onClick={(e) => { handleNutshell(e) }}
                                            disabled={nuthshellAPILoader === true}
                                        >
                                            {nuthshellAPILoader === false ? 'Sync With Nutshell' : <CircularProgress className='circular_spiner' size={20} color='white' />}
                                        </Button>
                                        : null
                                    }
                                    <NavLink style={{ textDecoration: 'none', color: '#067DE2' }} exact to="/application-listing">
                                        <Button
                                            className="secondary_btn"
                                            variant="outlined"
                                            color="primary"
                                            style={{ background: '#fff' }}
                                        >
                                            Cancel
                                        </Button>
                                    </NavLink>
                                </Grid>
                            </form>
                            </>
                        )
                    }
                </section>
            </div>
            <Snackbar open={openSnackbar}
                autoHideDuration={2000}
                onClose={handleClose1}
                anchorOrigin={{ vertical, horizontal }} >
                <Alert onClose={handleClose1} severity={severity_type}>
                    <div className="error" style={{ color: 'white' }}>
                        <span>{detail}</span>
                    </div>
                </Alert>
            </Snackbar>
            <Reviewmodal open={openSubmitLoanOfficerDetails} handleClickOpen={handleLoanOfficerDetails} deactiveUser={HandleLoanOfficerForm(forms, formik.values.originatorName, setAddLOButtonDisplay)} header="Loan Officer Details" />
            <Reviewmodal open={openSubmitLoanOfficerEditDetails} handleClickOpen={handleLoanOfficerEditDetails} deactiveUser={HandleLoanOfficerEditForm(forms, formik.values.originatorName, formik, setOpenSubmitLoanOfficerEditDetails, openSubmitLoanOfficerEditDetails, setConfirmLOButtonDisplay, confirmLOButtonDisplay, setLoanOfficerErrorMsg, setCheckLoResEdit, setDisplayAddLo, setResponseLOEditAdd)} header="Edit Loan Officer Details" />
            <Reviewmodal open={openSubmitRealEstateAgentEditDetails} handleClickOpen={handleRealEstateAgentEditDetails} deactiveUser={HandleRealEstateAgentEditForm(forms, formik.values.originatorName, formik, setOpenSubmitRealEstateAgentEditDetails, openSubmitRealEstateAgentEditDetails, setConfirmRSAButtonDisplay, confirmRSAButtonDisplay, setRealEstateAgentErrorMsg, setCheckRsaResEdit, setDisplayAddRSA, setResponseRSAEditAdd)} header="Edit Real Estate Agent Details" />
            <Reviewmodal open={openSubmitRealEstateAgentDetails} handleClickOpen={handleRealEstateAgentDetails} deactiveUser={HandleRealEstateAgentForm(forms, formik.values.originatorName, setAddRSAButtonDisplay)} header="Real Estate Agent Details" />
        </>
    )
}

export default Mgptab;